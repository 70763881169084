#brochure {
	position:fixed;
	bottom: 50px;
	right:-230px;
	@include transition (0.2s);
	z-index: 99;
}
#brochure:hover{right:-225px;}
#brochure input, #brochure select {
	background:$white;
	height: 34px;
	width: 100%;
	margin-bottom: 10px;
	font-size:16px;
	border-radius:0;
} 
#brochureBtn{
	cursor:pointer;
	background: url("../images/brochure.png") center center no-repeat $red;
	float: left;
	width: 100px;
	height: 292px;
}
#brochureContent{
	float:right;
	width:230px;
	height:340px;
	background-color:$red;
}
#brochure.open{
	right:0px !important;
	z-index:999999 !important;
}
.contentB {
    height: 300px;
    margin: 20px;
	overflow:hidden;
    width: 190px;
}
.contentB h2 {
	text-align:left;
	color: $white;
	font-size: 18px;
	margin:0;
	padding:0 0 5px;
}
#brochure button {
	color: $white;
	font-size:18px;
	background-color: $blue;
	border:none;
	width: 100%;
	line-height: 30px;
}
#brochure button:hover {background-color:$black;}