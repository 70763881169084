
.resources-list {
	overflow-x: scroll;
	.resource {
		text-align: center;
		position: relative;
		overflow: hidden;
		@include md {margin-bottom:15px;}
		.imgbox {
			position: relative;
			overflow: hidden;
			background-color: $black;
			margin-bottom: 15px;
			a {
				color: $white !important;
				display: block;
			}
			.resource-pad {
				position: absolute;
				width:100%;
				height:80px;
				bottom: 0;
				background-color: rgba($black,0.7);
				z-index: 3;
				@include transition(0.4s);
			}
			.resource-title {
				font-size: 18px;
				line-height: 1;
				padding:30px 0;
				text-transform: uppercase;
				@include sm {font-size: 24px;}
			}
			.valign {
				position: relative;
				top: 50%;
				transform: translateY(-50%);
				@include transition(0.4s);
			}
			img {
				position: relative;
				@include transition(0.4s);
				z-index: 1;
			}
		}
		&:hover {
			.resource-pad {
				height:100%;
			}
			.valign {
			}
			img {
				@include transform (1.15);
				opacity:0.6;
			}
		}
	}
}