.sidebar {
	width:25%;
	display: table-cell;
	vertical-align: top;
	background-color: $lgray;
	ul {
		@include list(0 0 20px);
	}
	.saltsidebar {
		padding-top:30px;
	}
	.menu {
		a {text-decoration: none;}
		>li {
			border-bottom:1px solid rgba($gray,0.3);
			>a {
				color: $red;
				font-size: 18px;
				font-weight: 500;
				padding:10px 15px;
				display: block;
				line-height: 1;
				&:hover {
					color:$white;
					background-color: $red;
				}
			}
		&.current-menu-item {
			a {
				color:$white;
				background-color: $red;
			}
		}
		&:first-child {
			a {
				color: $white;
				font-size: 20px;
				padding:20px 15px;
				text-transform: uppercase;
				background-color: $red;
				&:hover {
					color:$white;
					background-color: $blue;
				}
			}
		&.current-page-parent {
			a {
				color:$white;
				background-color: $blue;
				&:hover {
					color:$white;
					background-color: $red;
				}
			}
		}
		}
		}
	}
	
}