.image_hero.sticky {
	margin-top:86px;
}
.heroBG {
	background-size: cover;
	background-position: center;
	height:400px;
	display: table;
    width: 100%;
    position: relative;
    @include sm {height:500px;}
    &.mobile-hero {
    	height:250px;
    	@include sm {height: 310px;}
    	@include lg {height: 450px;}
    }
	.img_hero_content {
    	color: $white;
    	max-width: 500px;
    	margin:0 auto;
    	display: table-cell;
    	vertical-align: middle;
    	text-transform: uppercase;
    	position: relative;
		z-index: 5;
    	.pad {
    		padding:20px 0px;
    	}
	}
	.hero_content {
		max-width: 380px;
		margin:50px auto 0;
		text-align: center;
		display: table;
    	transform: scale(.9);
    	@include sm {
    		margin-top:170px;
    		max-width: 420px;
    		transform: scale(1);
    	}
	}
	.sm_text {
		@include sm {font-size: 1.3em;}
	}
	.lg_text {
		font-size: 2.5em;
		line-height: 1em;
		border-top:1px solid $white;
		padding-top:3px;
		margin-top:3px;
		@include sm {font-size: 3.2em;}

	}
	.xlg_text {
		font-size: 4.7em;
    	line-height: .9em;
		border-bottom:1px solid $white;
		padding-bottom:3px;
		margin-bottom:3px;
		@include sm {font-size: 6em;}
	}
	h2, h3 {
		color: $white;
		font-size: 1.5em;
		margin:0 0 20px;
	}
	h3 {
		font-size: 1.2em;
		font-weight: 600;
	}
	.btnbox {width:100%;text-align: center;}
}
.banner {
	color: $white;
    text-align: center;
	height:130px;
	background-color: $blue;
	@include sm { 
		font-size: 32px;
    	line-height: 34px;
	}
	@include md {height:160px;}
	.container {
		height: 100%;
		position: relative;
	}
	.banner-text {
		max-width: 600px;
    	margin: 0 auto;
	    position: relative;
	    top: 50%;
	    transform: translateY(-50%);
	    .banner-title {
		    font-size: 26px;
		    line-height: 30px;
	    }
	    .sub-title {
	    	@include RobotoC;
	    	font-size: 16px;
	    	font-weight: 300;
	    	line-height: 1.2;
	    	@include md {font-size: 18px;}
	    }
	}
}