#content a.donate_now {
    width: 229px;
    height: 60px;
    float: left;
    margin-bottom: 10px;
    background: url("images/donate_now.png") left top no-repeat;
}
#content a.donate_now:hover {
    background-position: left -60px;
}
#content a.donate_now:active {
    background-position: left bottom;
}
#grid_view_products_page_container {
    text-align: center;
    .wpsc-breadcrumbs {
        text-align: left;
        margin-bottom: 20px;
    }
}
.product_grid_display {
    display: table;
    margin:0 auto;
    .product_grid_item {
        -moz-box-shadow: none !important;
        -webkit-box-shadow: none !important;
        border: 2px solid $lgray !important;
        padding: 10px;
        margin:5px !important;
        display: inline-block;
        vertical-align: top;
        text-align: center;
        position: relative;
        height:250px;
        width: calc(50% - 10px);
        @include transition(0.25s);
        @include sm {margin: 0 5px 10px !important;}
        @include md {width: calc(25% - 20px);margin: 0 10px 10px !important;}
        &:hover {
            background: rgba($lgray, 0.6);
        }
    }
    .item_image a {
        a {
            display: block;
            height: 148px;
            width: 148px;
            margin: 0 auto;
        }
    }
    .grid_product_info {
        padding:0 !important;
    }
}
h2.prodtitles {
    font-size: 1.3em;
    display: block;
    width: 100%;
    float: left;
}
#sideshoppingcart {
    // display: none;
    display: block;
    @include transition(0.3s);
    @include lg {
        float:left;
        width:50%;
    }
    &.inside {
    }
    &.loaded {
        display: block;
        @include lg {
            float:left;
            width:50%;
        }
    }
    .shopping-cart-wrapper {
        .remove_button {
            background: url(../images/circle-x.png) no-repeat scroll 0 4px transparent;
            background-size: contain;
        }
    }
}
.checkout_cart {
    .header {
        th {
            padding:10px !important;
        }
    }
    .product_row {
        td.wpsc_product_name {
            width:auto !important;
        }
        form {
            input {
                display: block;
                margin:5px auto !important;
            }
            input[type="submit"] {
                float:none !important;
            }
        }
    }
    .wpsc_coupon_row {
        form {
            padding-right:0 !important;
            input#coupon_num {
                width:100% !important;
                max-width: 140px;
                margin:auto 10px auto auto !important;
            }
        }
    }
    @media only screen and (max-width: 760px), (min-device-width: 768px) and (max-device-width: 1024px)  {
    	/* Force table to not be like tables anymore */
    // 	table, thead, tbody, th, td, tr {
    // 		display: block;
    // 	}
    //     .header tr {
	// 		position: absolute;
	// 		top: -9999px;
	// 		left: -9999px;
	// 	}
    //
    // tr {
    //   margin: 0 0 1rem 0;
    // }
    //
    // tr:nth-child(odd) {
    //   background: #eaeaea;
    // }
    //
	// 	td {
	// 		/* Behave  like a "row" */
	// 		border: none;
	// 		border-bottom: 1px solid #eee;
	// 		position: relative;
	// 		padding-left: 50%;
	// 	}
    //
	// 	td:before {
	// 		/* Now like a table header */
	// 		position: absolute;
	// 		/* Top/left values mimic padding */
	// 		top: 0;
	// 		left: 6px;
	// 		width: 45%;
	// 		padding-right: 10px;
	// 		white-space: nowrap;
	// 	}

		/*
		Label the data
    You could also use a data-* attribute and content for this. That way "bloats" the HTML, this way means you need to keep HTML and CSS in sync. Lea Verou has a clever way to handle with text-shadow.
		*/
		// td:nth-of-type(1):before { content: "First Name"; }
		// td:nth-of-type(2):before { content: "Last Name"; }
		// td:nth-of-type(3):before { content: "Job Title"; }
		// td:nth-of-type(4):before { content: "Favorite Color"; }
		// td:nth-of-type(5):before { content: "Wars of Trek?"; }
		// td:nth-of-type(6):before { content: "Secret Alias"; }
		// td:nth-of-type(7):before { content: "Date of Birth"; }
		// td:nth-of-type(8):before { content: "Dream Vacation City"; }
		// td:nth-of-type(9):before { content: "GPA"; }
		// td:nth-of-type(10):before { content: "Arbitrary Data"; }
    }
}
.single_product_display {
    @include transition(0.3s);
    @include lg {
        float:left;
        width:50%;
    }
    .imagecol {
        width:45%;
        @include sm {width:25%;}
    }
    .product_description {margin:0;}
    .product_form {
        float: left;
        width: 50%;
        > fieldset, .wpsc_product_price {
            float:left;
            margin:0 5px;
            width: calc(50% - 10px);
            max-width: 150px;
        }
        .wpsc_product_price {
            padding:0 !important;
            label {
                font-size: 18px;
                font-weight: normal;
                display: block;
                width: 100%;
                padding: 0;
                margin-bottom: 20px;
                font-size: 21px;
                line-height: inherit;
                color: #333;
                border: 0;
                border-bottom: 1px solid #e5e5e5;
            }
        }
        .wpsc_buy_button_container {
            width:100%;
            float:left;
            text-align: left !important;
            margin-top: 15px;
            .wpsc_buy_button {
                font-size: 1em !important;;
                padding: 6px 25px !important;;
                @include md {padding: 10px 30px !important;;}
            }
        }
    }
}
.wpsc_page_numbers_bottom {
    margin-top:20px;
    span, a {
        display: inline-block;
        padding: 0px 9px;
        margin-right: 4px;
        border-radius: 3px;
        border: solid 1px #c0c0c0;
        background: #e9e9e9;
        box-shadow: inset 0px 1px 0px rgba(255,255,255, .8), 0px 1px 3px rgba(0,0,0, .1);
        font-size: .875em;
        font-weight: bold;
        text-decoration: none;
        color: #717171;
        text-shadow: 0px 1px 0px rgba(255,255,255, 1);
        &.current {
            border: none;
            background: #616161;
            box-shadow: inset 0px 0px 8px rgba(0,0,0, .5), 0px 1px 0px rgba(255,255,255, .8);
            color: #f0f0f0;
            text-shadow: 0px 0px 3px rgba(0,0,0, .5);
        }
        &:hover {
            background: #fefefe;
            background: -webkit-gradient(linear, 0% 0%, 0% 100%, from(#FEFEFE), to(#f0f0f0));
            background: -moz-linear-gradient(0% 0% 270deg,#FEFEFE, #f0f0f0);
        }
    }
}
.price_container {
    float: left;
}
.product_grid_display a.more_details:link, .product_grid_display a.more_details:visited {
    margin:0 !important;
}
// #grid_view_products_page_container .grid_more_info,
#grid_view_products_page_container .grid_more_info .quantity_container,
.wpsc_page_numbers_top {
    display: none;
}
#grid_view_products_page_container .wpsc_category_grid_item img {
    width: 148px !important;
    height: 148px !important;
}
.wpsc_category_details img,
.tax-wpsc_product_category.term-single img.post-image,
.tax-wpsc_product_category.term-series img.post-image {
    display: none;
}
#content .entry-content .post-image {
    padding: 4px;
    border: 1px solid #E6E6E6;
}

#content .post-image {
    margin: 0 10px 10px 0;
    padding: 4px;
    border: 1px solid #E6E6E6;
}

#content .archive-page {
    float: left;
    width: 45%;
    margin: 0;
    padding: 20px 20px 0 0;
}
.donate form input#donateForm {
    form {
        input#donateForm {
            border: 0;
            cursor: pointer;
            /*background: url("images/donate.png") top left no-repeat transparent;*/
            width: 102px;
            height: 36px;
            display: block;
            margin: 10px 0;
            text-indent: -9009px;
            &:hover {
                background-position: left -35px;
            }
            &:active {
                background-position: left bottom;
            }
        }
    }
}
.product_grid_display .wpsc_buy_button, #content .product_grid_display .wpsc_buy_button
.blueBtn,
.vfb-submit,
#content .wpsc-transaction-results-wrap a {
    background: $blue !important;
    border-color: $blue !important;
    text-shadow: none !important;
    &:hover {
      color: $white !important;
      background-color: $dblue !important;
    }
}
#content h2.prodtitle {
    font-size: 14px !important;
    margin: 5px 0;
    height: 35px;
    text-align: center !important;
    line-height: 16px !important;
}
#content .grid_product_info p {
    margin-bottom: 5px;
    color: #777;
}
#shopping_cart {
    float: left;
    width: 100%;
    /* background-color: #fff; */
}
#primary #shopping_cart a {
    padding: 0;
    font-size: 12px;
    text-transform: none !important;
}
#primary #shopping_cart a:hover {
    color: #295183;
    text-decoration: underline;
    background: none;
}
#shopping_cart h3 {
    margin: 0;
    color: #295183;
    font-size: 1.70em;
    font-weight: bold;
}
#widgetshoppingcart,
#sideshoppingcart {
    padding: 7px 15px;
}
#shopping_cart .cart-widget-remove,
#shopping_cart #remove,
#single_product_page_container .custom_meta {
    display: none;
}
#shopping_cart #cart-widget-links a {
    float: left;
    width: 50% !important;
}
.shopping-cart-wrapper .shoppingcart table th {
    width: 33%;
}
.single-wpsc-product .entry-content fieldset legend {
    background: none;
}
#content .wpsc-transaction-results-wrap a {
    padding: 10px 24px !important;
    margin: 10px 0;
}
#content .productcol h3 {
    clear: none;
    margin-bottom: 10px;
}
#content .imagecol {
    margin: 0 15px 15px 0 !important;
}
.wpsc_checkout_forms div.wpsc_email_address,
#content .wpsc_checkout_forms .wpsc_checkout_table {
    float: left;
    width: 45%;
    margin-right: 5%;
}
#content #checkout_page_container input.wpsc-visitor-meta {
    width: 230px;
}
#content label.wpsc_email_address {
    color: #274c7a;
    font-weight: bold;
    font-size: 18px;
    line-height: 1.5em;
    margin: 0 0 20px 0;
    font-family: 'Oxygen', sans-serif !important;
}
.wpsc_also_bought_item {
    float: left;
    margin: 0 10px 10px 0;
    text-align: center;
    font-size: .8em;
    line-height: 1.1em;
}
.wpsc_also_bought_item a {
    width: 100%;
    float: left;
}
.highlight {
    background-color: #ffea00;
}

.clear {
    clear: both;
}
hr.dashed {
    margin: 5px 0;
    border: 1px dashed #555555;
}

// .blueBtn,
// .vfb-submit,
// #content .wpsc-transaction-results-wrap a {
//     -moz-box-shadow: inset 0px 1px 0px 0px #97c4fe;
//     -webkit-box-shadow: inset 0px 1px 0px 0px #c3fe97;
//     box-shadow: inset 0px 1px 0px 0px #c3fe97;
//     background: -webkit-gradient( linear, left top, left bottom, color-stop(0.05, #80ce50), color-stop(1, #68a045) );
//     background: -moz-linear-gradient( center top, #80ce50 5%, #1e62d0 100% );
//     filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#80ce50', endColorstr='#68a045');
//     background-color: #7ef63d;
//     -moz-border-radius: 6px;
//     -webkit-border-radius: 6px;
//     border-radius: 6px;
//     border: 1px solid #4a9c1e;
//     display: inline-block;
//     color: #ffffff !important;
//     font-size: 15px;
//     padding: 6px 24px;
//     text-decoration: none;
//     text-shadow: 1px 1px 0px #367613;
//     cursor: pointer;
//     text-decoration:none !important;
// }
// .blueBtn a {color:#fff !important;text-decoration:none !important;}
// .blueBtn:hover,
// .vfb-submit:hover,
// #content .wpsc-transaction-results-wrap a:hover {
//     background: -webkit-gradient( linear, left top, left bottom, color-stop(0.05, #68a045), color-stop(1, #80ce50) );
//     background: -moz-linear-gradient( center top, #68a045 5%, #3d94f6 100% );
//     filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#68a045', endColorstr='#80ce50');
//     background-color: #7ef63d;
//     text-decoration: none;
// }
.blueBtn:active,
.vfb-submit:active {
    position: relative;
    top: 1px;
}
.blueBtn:focus,
.vfb-submit:focus {
    outline: none;
}

#content table {
    border: 1px solid #e7e7e7;
    margin: 0 -1px 24px 0;
    text-align: left;
    width: 100%;
}
#content tr th,
#content thead th {
    color: #777;
    font-size: 12px;
    font-weight: bold;
    line-height: 18px;
    padding: 9px 24px;
}
#content tr td {
    border-top: 1px solid #e7e7e7;
    padding: 6px 24px;
}
#content tr.odd td {
    background: #f2f7fc;
}
