$blue	  			:#204b7a; // Blue in logo
$dblue	  			:#003459;
$lblue	  			:#5d8fc8;
$red	 			:#8b2d26;
$gray				:#464646; // Body Color
$lgray	 			:#ececec;
$mgray				:#d2d2d2;
$dgray	 			:#303030;
$white 				:#fff;
$black 				:#111; 
$bgray				:#718CAA;
$green				:#197B5D;

.blue {color: $blue;}
.dblue {color: $dblue;}
.lblue {color: $lblue;}
.red {color: $red;}
.gray {color: $gray;}
.lgray {color: $lgray;}
.dgray {color: $dgray;}
.white {color: $white;}
.black {color: $black;}

.blueBG {background-color: $blue;}
.dblueBG {background-color: $dblue;}
.lblueBG {background-color: $lblue;}
.redBG {background-color: $red;}
.grayBG {background-color: $gray;}
.lgrayBG {background-color: $lgray;}
.dgrayBG {background-color: $dgray;}
.whiteBG {background-color: $white;}
.blackBG {background-color: $black;}