
#need-prayer {
	color:$white;
	text-align: center;
	margin:20px 0;
	.prayerImg {
		background-position: right top;
		background-size: cover;
		background-repeat: no-repeat;
		@include md {background-position: left top;}
	}
	.pad {
		padding:30px 20px;
		@include sm {
			padding: 40px 20px;
		    max-width: 450px;
		    margin: 0 0 0 auto;
		}
		@include md {
			padding: 70px 20px;
		    max-width: 500px;
		    margin-right: 40px;
		}
		@include lg {
			padding: 90px 60px 90px 20px;
		    max-width: 600px;
		}
		@include xl {
			padding: 110px 60px 110px 20px;
		    margin-right: 60px;
		}
		@include xxl {margin-right: 25%;}
	}
	h3 {
		color: $white;
		text-align: center;
		@include lg {
			font-size: 34px;
			margin-bottom:30px;
		}
	}
	p {
		line-height: 1.2em;
		margin-bottom:15px;
		@include md {line-height: 1.4em;}
		@include lg {
			font-size: 20px;
			font-weight: 300;
			line-height: 1.4em;
			margin-bottom:30px;
		}
	}
	.btn {
		color: $white;
		background-color: $blue;
		border:none;
		&:hover {
			color: $gray;
			background-color: $white;
		}
	}
}