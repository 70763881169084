.nav_top {
	@include sm {
		float:right;
	}
	ul {
		@include list(0 auto);
		text-align: center;
		display: table;
	}
	ul>li {
		float:left;
		line-height: 40px;
	}
	ul>li>a {
		color: $gray;
		font-weight: 400;
		padding:0 10px;
		text-decoration: none;
		display: block;
	}
	ul>li>a:hover {
		background-color:$white;
	}
}
#main_nav {
	float:right;
	position: relative;
}
.mainNav {
	ul {
		padding:0;
		margin:0;
		list-style: none;
		text-align: center;
	}
	.menu > li {
		float:left;
		height:85px;
		line-height: 85px;
		margin: 0;
		position: relative;
		&.one-column {
			
		}
		&.two-column {
			position: initial;
			>.sub-menu {
				text-align: center;
			}
			>.sub-menu >li {
				display: inline-block;
				vertical-align: top;
				line-height: initial;
				width: 50%;
				margin-left: -5px;
			}
			>.sub-menu >li>a{
				font-weight: 600;
				line-height: 30px;
			}
		}
		> a {
			color: $gray;
			font-size: 20px;
		    font-weight: 400;
			padding:0 20px;
		    border-radius: 0;
		    height: 100%;
		    @include lg {padding:0 20px;}
		}
		> .sub-menu {
			opacity: 0;
			top: 95px;
		    left: 0;
		    width:100%;
		    padding: 10px 15px 15px;
		    box-shadow: 0 1px 2px rgba(0,0,0,.2);
		    visibility: hidden;
			background-color: $white;
		}
		&:nth-child(4) > .sub-menu,
		&:last-child > .sub-menu {
			left:auto;
			right: 0;
		}
		&:hover {
			> .sub-menu {
				opacity: 1;
			    top: 85px;
			    visibility: visible;
			}
		}
	}
	.menu a {
		display: block;
		text-decoration: none;
		position: relative;
		@include transition(0.2s);
	}
	.menu > li > a:hover,
	.menu > li.current-menu-item > a,
	.menu > li.current-menu-parent > a {
		color: $blue;
		background-color: $white;
	}
	.menu > li:hover > a:after,
	.menu > li.current-menu-item > a:after,
	.menu > li.current-menu-parent > a:after{
		content:'';
	    position: absolute;
	    height: 3px;
	    width: 100%;
	    background-color: $red;
	    bottom: 0;
	    left: 0;
		@include transition(0.2s);
	}
	.sub-menu {
		position: absolute;
		min-width: 200px;
	    z-index: 999;
	    text-align: left;
		@include transition(0.2s);
		li {
			text-align: left;
			line-height: 30px;
		}
		> li > a {
			color: $blue;
			font-size: 16px;
			font-weight: 300;
			padding:0 10px;
			white-space: nowrap;
		}
		> li > a:hover {
			color: $white;
			background-color: $blue;
		}
		.sub-menu {
			position: relative;
		}
	}
	/*.menu > li:last-child > .sub-menu {
		left:auto;
		right:0;
	}*/
	/*.sub-menu > li {line-height: 34px;}*/
}
.sticky {	
	.mainNav {
		.menu > li {
			height: 70px;
			line-height: 70px;
		}
		.menu > li:hover > .sub-menu {
		    top: 70px;
		}
	}
}
