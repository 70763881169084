.salt {
	#hdr_bottom .logo img {-webkit-filter: hue-rotate(130deg);filter: hue-rotate(130deg);}
	.banner,
	.mainNav .sub-menu>li>a:hover {
		background-color: $red;
	}
	.mainNav .menu>li>a:hover,
	.mainNav .sub-menu>li>a,
	#footer h3, .salt-content a,
	#footer .ftr-social .social-title {
		color: $red;
	}
	.mainNav .sub-menu>li>a:hover {
		color: $white;
	}
}