.top_social {
	float:left;
	.smedia {
		@include list(0);
		li {
			float:left;
			height: 40px;
			line-height: 40px;
		}
		a {
			color: $gray;
			font-size: 18px;
			padding:0 10px;
			display: block;
			&:hover {
				background-color:$white;
			}
		}
	}
}