
#book_container {
	text-align: center;
	padding:15px 0;
    @include md {padding: 30px 0;}
    .book {
        &:focus {
            outline: -webkit-focus-ring-color auto 0px;
        }
    	.book-img {
    		display: table;
    		max-width: 200px;
    		margin:0 auto;
    		margin: 0 auto 10px;
		    border: 1px solid $mgray;
		    padding: 10px;
    	}
    	.book-title {
    		font-size: 20px;
    		font-weight: bold;
    		margin-bottom: 5px;
    	}
    	.book-subtitle {
    		font-style: italic;
    		font-weight: 300;
    		margin-bottom:5px;
    	}
    	.book-author {
    		font-weight: 600;
    	}
    	.btnbox {
    		.book_link {
    			color: $white;
    			display: table;
    			padding:6px 15px;
    			margin:5px auto 10px;
    			background-color: $black;
    			text-decoration: none;
                &:hover {
                    background-color: $blue;
                }
    		}
    	}
    	.book-img {}
    }
    .slick-dots {
        @include list(0 auto 20px);
        padding:0 20px;
        text-align: center;
        display: table !important;
        position: relative;
        li {
            display: inline-block;
            line-height: 15px;
        }
        li button {
            border:none;
            text-indent:-9999px;
            margin:0 5px;
            padding:0px;
            height:15px;
            width:15px;
            border-radius:30px;
            background-color: rgba($lgray,0.7);
        	border:1px solid $mgray;
            &:focus {outline: -webkit-focus-ring-color auto 0px;}
        }
        li.slick-active button,
        li:hover button {
        	background-color: $blue;
        	border:1px solid $blue;
        }
    }
    .slick-arrow:hover {background-color: rgba($black,0.9);}
    .slick-arrow {
    	color: $white;
    	font-size: 20px;
        top:50%;
        margin-top:-30px;
        position: absolute;
        cursor: pointer;
        padding:10px;
        background-color: rgba($black,0.5);
        @include transition(0.3s);
        z-index: 99;
    }
    .arrow-left {left:0;}
    .arrow-right {right:0;}
}