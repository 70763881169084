.blue {
  color: #204b7a; }

.dblue {
  color: #003459; }

.lblue {
  color: #5d8fc8; }

.red {
  color: #8b2d26; }

.gray {
  color: #464646; }

.lgray {
  color: #ececec; }

.dgray {
  color: #303030; }

.white {
  color: #fff; }

.black {
  color: #111; }

.blueBG {
  background-color: #204b7a; }

.dblueBG {
  background-color: #003459; }

.lblueBG {
  background-color: #5d8fc8; }

.redBG {
  background-color: #8b2d26; }

.grayBG {
  background-color: #464646; }

.lgrayBG {
  background-color: #ececec; }

.dgrayBG {
  background-color: #303030; }

.whiteBG {
  background-color: #fff; }

.blackBG {
  background-color: #111; }

.upper {
  text-transform: uppercase; }

.nopadding {
  padding: 0; }

body {
  color: #464646;
  font-size: 16px;
  line-height: 22px; }
  @media (min-width: 992px) {
    body {
      font-size: 18px;
      line-height: 24px; } }

a, .btn {
  -webkit-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease; }

a {
  color: #204b7a; }

a:active {
  outline: 0; }

img {
  max-width: 100%; }

.clear {
  clear: both; }

.btn, .wpsc_buy_button,
.blueBtn,
.vfb-submit,
#content .wpsc-transaction-results-wrap a {
  color: #fff;
  font-size: 1em;
  font-weight: 400;
  padding: 6px 25px;
  border-radius: 0;
  text-align: center;
  background: #204b7a;
  border-color: #204b7a; }
  .btn:hover, .wpsc_buy_button:hover,
  .blueBtn:hover,
  .vfb-submit:hover,
  #content .wpsc-transaction-results-wrap a:hover {
    color: #fff;
    background-color: #003459; }
  @media (min-width: 992px) {
    .btn, .wpsc_buy_button,
    .blueBtn,
    .vfb-submit,
    #content .wpsc-transaction-results-wrap a {
      padding: 10px 30px; } }

.btn.btn-red {
  color: #fff !important;
  background: #8b2d26;
  border-color: #8b2d26; }
  .btn.btn-red:hover {
    color: #fff;
    background-color: #204b7a;
    border-color: #204b7a; }

.full {
  float: left;
  width: 100%; }

.thin {
  font-weight: 300; }

.bold {
  font-weight: bold; }

.upper {
  text-transform: uppercase; }

@media (min-width: 992px) {
  .mobile-only {
    display: none; } }

.desktop-only {
  display: none; }
  @media (min-width: 992px) {
    .desktop-only {
      display: block; } }

.divider {
  text-align: center;
  float: left;
  width: 100%;
  height: 40px;
  margin: 15px 0;
  display: block; }
  .divider .pad {
    display: table;
    width: 100%;
    padding: 0 15px;
    position: relative; }
    .divider .pad:before, .divider .pad:after {
      content: '';
      position: absolute;
      height: 1px;
      width: 32%;
      background: #d2d2d2;
      margin: 20px 0; }
      @media (min-width: 768px) {
        .divider .pad:before, .divider .pad:after {
          width: 35%; } }
      @media (min-width: 992px) {
        .divider .pad:before, .divider .pad:after {
          width: 38%; } }
      @media (min-width: 1200px) {
        .divider .pad:before, .divider .pad:after {
          width: 40%; } }
      @media (min-width: 1400px) {
        .divider .pad:before, .divider .pad:after {
          width: 42%; } }
    .divider .pad:before {
      left: 15px; }
    .divider .pad:after {
      right: 15px; }
  .divider span {
    display: inline-block; }
  .divider img {
    max-height: 40px; }

.animateBlock {
  margin-top: 20px;
  display: inline-block;
  opacity: 0;
  filter: alpha(opacity=0);
  position: relative;
  -webkit-transition: all .4s ease-in;
  -moz-transition: all .4s ease-in;
  -ms-transition: all .4s ease-in;
  -o-transition: all .4s ease-in;
  transition: all .4s ease-in; }
  .animateBlock.left {
    left: -20%; }
  .animateBlock.right {
    right: -20%; }
  .animateBlock.up {
    bottom: -20%; }

.animated {
  opacity: 1;
  filter: alpha(opacity=100); }
  .animated.left {
    left: 0%; }
  .animated.right {
    right: 0%; }
  .animated.up {
    bottom: 0%; }

.blue {
  color: #204b7a; }

.dblue {
  color: #003459; }

.lblue {
  color: #5d8fc8; }

.red {
  color: #8b2d26; }

.gray {
  color: #464646; }

.lgray {
  color: #ececec; }

.dgray {
  color: #303030; }

.white {
  color: #fff; }

.black {
  color: #111; }

.blueBG {
  background-color: #204b7a; }

.dblueBG {
  background-color: #003459; }

.lblueBG {
  background-color: #5d8fc8; }

.redBG {
  background-color: #8b2d26; }

.grayBG {
  background-color: #464646; }

.lgrayBG {
  background-color: #ececec; }

.dgrayBG {
  background-color: #303030; }

.whiteBG {
  background-color: #fff; }

.blackBG {
  background-color: #111; }

/*@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?1q8zfw');
  src:  url('fonts/icomoon.eot?1q8zfw#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?1q8zfw') format('truetype'),
    url('fonts/icomoon.woff?1q8zfw') format('woff'),
    url('fonts/icomoon.svg?1q8zfw#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}*/
@font-face {
  font-family: 'icomoon';
  src: url("fonts/icomoon.eot?cvu8hf");
  src: url("fonts/icomoon.eot?cvu8hf#iefix") format("embedded-opentype"), url("fonts/icomoon.ttf?cvu8hf") format("truetype"), url("fonts/icomoon.woff?cvu8hf") format("woff"), url("fonts/icomoon.svg?cvu8hf#icomoon") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

body {
  font-family: 'Roboto Condensed', sans-serif; }

h1, h2, h3, .home .h2,
.ftr-social .social-title {
  font-family: 'Alice', serif; }

.service-text, .cta-small-title, .banner {
  font-family: 'Noto Serif', serif; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  line-height: 1.1em;
  margin: 0 0 20px; }

p {
  margin: 0 0 15px; }
  @media (min-width: 1200px) {
    p {
      margin-bottom: 20px; } }

.home h1, .home .h1 {
  color: #204b7a;
  font-size: 32px;
  line-height: 36px; }
  @media (min-width: 992px) {
    .home h1, .home .h1 {
      font-size: 36px;
      line-height: 40px; } }

.home h2, .home .h2 {
  color: #303030;
  font-size: 28px;
  line-height: 32px; }
  @media (min-width: 992px) {
    .home h2, .home .h2 {
      font-size: 32px;
      line-height: 36px; } }

.home h3, .home .h3 {
  color: #111;
  font-size: 24px;
  line-height: 28px; }
  @media (min-width: 992px) {
    .home h3, .home .h3 {
      font-size: 28px;
      line-height: 32px; } }

.home h4, .home .h4 {
  font-size: 20px;
  line-height: 24px; }
  @media (min-width: 992px) {
    .home h4, .home .h4 {
      font-size: 24px;
      line-height: 28px; } }

.icon-hand:before {
  content: "\e901"; }

.icon-bible:before {
  content: "\e902"; }

.icon-cross:before {
  content: "\e903"; }

.icon-church-new:before {
  content: "\e904"; }

.icon-church:before {
  content: "\e900"; }

.icon-headphones:before {
  content: "\e910"; }

.icon-location:before {
  content: "\e948"; }

.icon-youtube:before {
  content: "\ea9e"; }

#book_container {
  text-align: center;
  padding: 15px 0; }
  @media (min-width: 992px) {
    #book_container {
      padding: 30px 0; } }
  #book_container .book:focus {
    outline: -webkit-focus-ring-color auto 0px; }
  #book_container .book .book-img {
    display: table;
    max-width: 200px;
    margin: 0 auto;
    margin: 0 auto 10px;
    border: 1px solid #d2d2d2;
    padding: 10px; }
  #book_container .book .book-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 5px; }
  #book_container .book .book-subtitle {
    font-style: italic;
    font-weight: 300;
    margin-bottom: 5px; }
  #book_container .book .book-author {
    font-weight: 600; }
  #book_container .book .btnbox .book_link {
    color: #fff;
    display: table;
    padding: 6px 15px;
    margin: 5px auto 10px;
    background-color: #111;
    text-decoration: none; }
    #book_container .book .btnbox .book_link:hover {
      background-color: #204b7a; }
  #book_container .slick-dots {
    margin: 0 auto 20px;
    padding: 0;
    list-style: none;
    padding: 0 20px;
    text-align: center;
    display: table !important;
    position: relative; }
    #book_container .slick-dots li {
      display: inline-block;
      line-height: 15px; }
    #book_container .slick-dots li button {
      border: none;
      text-indent: -9999px;
      margin: 0 5px;
      padding: 0px;
      height: 15px;
      width: 15px;
      border-radius: 30px;
      background-color: rgba(236, 236, 236, 0.7);
      border: 1px solid #d2d2d2; }
      #book_container .slick-dots li button:focus {
        outline: -webkit-focus-ring-color auto 0px; }
    #book_container .slick-dots li.slick-active button,
    #book_container .slick-dots li:hover button {
      background-color: #204b7a;
      border: 1px solid #204b7a; }
  #book_container .slick-arrow:hover {
    background-color: rgba(17, 17, 17, 0.9); }
  #book_container .slick-arrow {
    color: #fff;
    font-size: 20px;
    top: 50%;
    margin-top: -30px;
    position: absolute;
    cursor: pointer;
    padding: 10px;
    background-color: rgba(17, 17, 17, 0.5);
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
    z-index: 99; }
  #book_container .arrow-left {
    left: 0; }
  #book_container .arrow-right {
    right: 0; }

#brochure {
  position: fixed;
  bottom: 50px;
  right: -230px;
  -webkit-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
  z-index: 99; }

#brochure:hover {
  right: -225px; }

#brochure input, #brochure select {
  background: #fff;
  height: 34px;
  width: 100%;
  margin-bottom: 10px;
  font-size: 16px;
  border-radius: 0; }

#brochureBtn {
  cursor: pointer;
  background: url("../images/brochure.png") center center no-repeat #8b2d26;
  float: left;
  width: 100px;
  height: 292px; }

#brochureContent {
  float: right;
  width: 230px;
  height: 340px;
  background-color: #8b2d26; }

#brochure.open {
  right: 0px !important;
  z-index: 999999 !important; }

.contentB {
  height: 300px;
  margin: 20px;
  overflow: hidden;
  width: 190px; }

.contentB h2 {
  text-align: left;
  color: #fff;
  font-size: 18px;
  margin: 0;
  padding: 0 0 5px; }

#brochure button {
  color: #fff;
  font-size: 18px;
  background-color: #204b7a;
  border: none;
  width: 100%;
  line-height: 30px; }

#brochure button:hover {
  background-color: #111; }

#content .bs_title {
  margin-bottom: 10px; }

.btn_group {
  width: 100%;
  float: left;
  clear: both;
  margin-bottom: 15px; }

.filter-title {
  margin: 0 5px 5px 0; }

.business ul {
  margin: 0;
  padding: 0;
  list-style: none; }

.filter-title, #filterOptions > li {
  float: left;
  line-height: 28px; }

#filterOptions > li > a {
  display: block;
  margin-bottom: 3px;
  padding: 0 8px;
  border: 1px solid #ddd;
  background-color: rgba(255, 255, 255, 0.8);
  -webkit-transition: .25s ease;
  -moz-transition: .25s ease;
  -o-transition: .25s ease;
  -ms-transition: .25s ease;
  transition: .25s ease;
  text-decoration: none !important; }

#filterOptions > li.active > a,
#filterOptions > li:hover > a {
  color: #fff;
  background-color: #125da8; }

.business ul.bsgrid {
  display: table;
  margin-right: -15px;
  margin-left: -15px; }
  .business ul.bsgrid .item {
    text-align: center;
    position: relative;
    margin-bottom: 15px; }

.inner {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  z-index: 100; }
  .inner .item-hover {
    height: 100%;
    position: relative;
    text-align: center;
    display: block;
    width: 100%;
    z-index: 100;
    border: 1px solid #555; }
    .inner .item-hover .mask {
      background-color: #000;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
      filter: alpha(opacity=50);
      height: 100%;
      opacity: 0;
      width: 100%;
      z-index: 99;
      top: 0;
      left: 0;
      position: absolute;
      -webkit-transition: all 300ms ease-out;
      -moz-transition: all 300ms ease-out;
      -o-transition: all 300ms ease-out;
      transition: all 300ms ease-out; }
  .inner .item-title,
  .inner .item-info {
    position: absolute; }
  .inner .item-title {
    color: #555;
    width: 100%;
    bottom: 0;
    z-index: 98;
    padding: 10px 0;
    background-color: rgba(255, 255, 255, 0.7);
    -webkit-transition: all 300ms ease-out;
    -moz-transition: all 300ms ease-out;
    -o-transition: all 300ms ease-out;
    transition: all 300ms ease-out; }
  .inner .item-info {
    display: table;
    z-index: 1000;
    height: 100%;
    width: 100%;
    top: 50%;
    transform: translateY(-50%); }

.headline {
  display: table-cell;
  vertical-align: middle; }

.item-img {
  overflow: hidden;
  width: 100%;
  height: 100%;
  max-width: 100%;
  vertical-align: middle;
  position: relative;
  z-index: 9;
  transition: all 0.3s ease-in-out 0s; }

.item-info,
.item-hover .mask,
.item-hover:hover .item-title {
  opacity: 0; }

.item-hover:hover .item-info, .item-hover:hover .mask {
  opacity: 0.7; }

.item-hover:hover .item-img {
  transform: scale(1.1, 1.1) !important; }

.single-business > .btn {
  margin-bottom: 20px; }

.single-business .business-info .business-row {
  margin-bottom: 10px;
  padding-bottom: 10px;
  position: relative;
  display: flex; }
  .single-business .business-info .business-row:before, .single-business .business-info .business-row:after {
    content: '';
    position: absolute;
    left: 15px;
    width: 92%;
    height: 1px; }
  .single-business .business-info .business-row:after {
    content: '';
    position: absolute;
    bottom: 0;
    background-color: rgba(210, 210, 210, 0.9); }
  .single-business .business-info .business-row:first-child, .single-business .business-info .business-row.business-owner {
    padding-top: 10px; }
    .single-business .business-info .business-row:first-child:before, .single-business .business-info .business-row.business-owner:before {
      top: 0;
      background-color: rgba(210, 210, 210, 0.9); }
  .single-business .business-info .business-row:last-child:after {
    display: none; }
  .single-business .business-info .business-row.business-map .b-map {
    margin-top: 15px; }
  .single-business .business-info .business-row.business-map .btn {
    margin-top: 15px; }

/* Small devices (large mobile, 500px and up) */
@media (min-width: 500px) {
  .bsgrid .item {
    width: 50%; } }

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .bsgrid .item {
    width: 33.33333333%; } }

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .bsgrid .item {
    width: 25%; } }

.acf-map {
  width: 100%;
  height: 0;
  padding-bottom: 50%; }
  .acf-map img {
    max-width: inherit !important; }

@media (min-width: 1400px) {
  .container.lgContainer {
    width: 1270px; } }

@media (min-width: 1600px) {
  .container.lgContainer {
    width: 1470px; } }

@media (min-width: 1800px) {
  .container.lgContainer {
    width: 1700px; } }

@media (min-width: 2000px) {
  .container.lgContainer {
    width: 1900px; } }

#main {
  padding: 20px 15px; }
  @media (min-width: 1200px) {
    #main {
      padding: 40px 15px; } }

.has-sidebar #main {
  padding: 20px 15px; }
  @media (min-width: 992px) {
    .has-sidebar #main {
      padding: 0 15px; } }
  @media (min-width: 1200px) {
    .has-sidebar #main {
      padding: 0 15px; } }

@media (min-width: 992px) {
  .main-content.with-sidebar {
    display: table-cell;
    vertical-align: top;
    width: 75%;
    padding: 20px 0 20px 30px; } }

#content {
  position: relative;
  padding: 20px 0 10px;
  display: table;
  width: 100%; }

@clude sm {
  #content {
    padding: 30px 0 20px; } }

@clude md {
  #content {
    padding: 40px 0 30px; } }
  #content h1, #content h2, #content h3, #content h4, #content h5, #content h6 {
    font-weight: bold; }
  #content p a, #content ul a {
    color: #5d8fc8; }
    #content p a.btn, #content ul a.btn {
      color: #fff; }
  #content .visual-form-builder fieldset {
    border: none;
    border-radius: 0;
    background: none; }
  #content .visual-form-builder .vfb-legend {
    display: none; }
  #content .visual-form-builder ul.vfb-section {
    margin: 0 !important; }
  #content .visual-form-builder ul.vfb-section > li {
    padding: 0 !important;
    margin-top: 10px !important; }
    @media (min-width: 768px) {
      #content .visual-form-builder ul.vfb-section > li {
        margin-top: 20px !important; } }
  #content .visual-form-builder ul.vfb-section label {
    color: #464646;
    font-size: 1.1em; }
  #content .visual-form-builder ul.vfb-section > li > input,
  #content .visual-form-builder ul.vfb-section > li textarea,
  #content .visual-form-builder ul.vfb-section > li select,
  #content .visual-form-builder ul.vfb-section > li input {
    padding: 0 5px;
    line-height: 30px;
    height: 30px;
    background: none repeat scroll 0 0 #fefefe;
    border-radius: 0; }
  #content .visual-form-builder ul.vfb-section > li textarea.vfb-textarea.vfb-medium {
    padding: 5px;
    line-height: 1.1;
    height: 60px; }
  #content .visual-form-builder ul.vfb-section > li textarea.vfb-textarea.vfb-large {
    padding: 5px;
    line-height: 1.1;
    height: 90px; }
  #content .visual-form-builder .vfb-item-submit {
    text-align: center; }
  #content .visual-form-builder ul.vfb-section > li.vfb-item-submit > input {
    color: #fff;
    font-size: 1.3em;
    width: 60%;
    max-width: 300px;
    border: none;
    background: #8b2d26;
    -webkit-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease; }
    @media (min-width: 768px) {
      #content .visual-form-builder ul.vfb-section > li.vfb-item-submit > input {
        line-height: 40px;
        height: 40px; } }
  #content .visual-form-builder ul.vfb-section > li.vfb-item-submit > input:hover {
    background: #204b7a; }
  #content .faqs-list .faq {
    display: table;
    width: 100%;
    margin-bottom: 10px; }
    #content .faqs-list .faq .faq-q {
      font-size: 1.1em;
      font-weight: 600;
      margin-bottom: 10px; }
  #content .contact-info {
    margin-bottom: 20px; }
    #content .contact-info .info-box {
      margin-bottom: 15px; }
      #content .contact-info .info-box > * {
        display: table;
        width: 100%;
        padding-bottom: 10px;
        max-width: 350px;
        margin: 0 auto; }
    #content .contact-info .info-label {
      font-weight: 600;
      line-height: 1;
      float: left;
      width: 38%; }
      @media (min-width: 768px) {
        #content .contact-info .info-label {
          width: 32%; } }
      @media (min-width: 1200px) {
        #content .contact-info .info-label {
          width: 40%; } }
    #content .contact-info .info-text {
      float: left; }
  #content .navigation .nav-previous {
    float: left; }
  #content .navigation .nav-next {
    float: right; }
  #content .radiospot {
    margin-bottom: 10px; }
    @media (min-width: 768px) {
      #content .radiospot {
        margin-bottom: 20px; } }
    @media (min-width: 768px) {
      #content .radiospot:nth-child(2n) {
        clear: left; } }
  #content .sofia .tg-item-title,
  #content .sofia .tg-item-title a {
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: normal;
    font-size: 1em;
    line-height: 1.1;
    padding: 0 !important;
    text-transform: none; }

.modular .modpad {
  padding: 20px 0;
  overflow-y: scroll; }
  @media (min-width: 768px) {
    .modular .modpad {
      padding: 30px 0; } }
  @media (min-width: 992px) {
    .modular .modpad {
      padding: 40px 0; } }
  @media (min-width: 1200px) {
    .modular .modpad {
      padding: 50px 0; } }

.modular .icons, .modular #content {
  padding: 0; }

.modular .contentbox .menu-page-menu-container ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: table; }
  .modular .contentbox .menu-page-menu-container ul li {
    border-bottom: 1px solid #d2d2d2; }
    .modular .contentbox .menu-page-menu-container ul li:first-child {
      border-top: 1px solid #d2d2d2; }
    .modular .contentbox .menu-page-menu-container ul li a {
      color: #718CAA !important;
      text-decoration: none;
      line-height: 1;
      display: block;
      padding: 15px 25px 15px 15px;
      position: relative; }
      @media (min-width: 768px) {
        .modular .contentbox .menu-page-menu-container ul li a {
          padding: 20px 30px 20px 20px; } }
      .modular .contentbox .menu-page-menu-container ul li a:after {
        content: '\f054';
        font-family: FontAwesome;
        position: absolute;
        right: 7px;
        -webkit-transition: all 0.25s ease;
        -o-transition: all 0.25s ease;
        -ms-transition: all 0.25s ease;
        -moz-transition: all 0.25s ease;
        transition: all 0.25s ease; }
      .modular .contentbox .menu-page-menu-container ul li a:hover {
        color: #111 !important;
        /*padding:15px 20px 15px 20px;*/
        background-color: #ececec;
        /*@include sm {padding:20px 25px 20px 25px;}*/ }
        .modular .contentbox .menu-page-menu-container ul li a:hover:after {
          right: 2px; }

.tablebox {
  display: table;
  width: 100%; }

.cta_block > .pad {
  padding: 20px 0; }
  @media (min-width: 992px) {
    .cta_block > .pad {
      padding: 40px 30px;
      display: table;
      width: 100%;
      max-width: 1600px;
      margin: 0 auto; } }

@media (min-width: 992px) {
  .cta_block .cta {
    padding: 0 10px; } }

.cta_block .cta_link, .cta_block .cta-text,
.cta_block .overlay {
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease; }

.cta_block .cta_link {
  color: #fff;
  text-align: center;
  text-decoration: none;
  display: block;
  height: 270px;
  position: relative;
  max-width: 470px;
  margin: 0 auto 15px; }
  @media (min-width: 768px) {
    .cta_block .cta_link {
      margin-bottom: 20px; } }
  @media (min-width: 1200px) {
    .cta_block .cta_link {
      height: 310px; } }
  @media (min-width: 1400px) {
    .cta_block .cta_link {
      height: 380px; } }
  @media (min-width: 1600px) {
    .cta_block .cta_link {
      height: 400px; } }
  .cta_block .cta_link:hover .overlay {
    opacity: 0.7; }
  .cta_block .cta_link:hover .cta-text {
    padding-top: 70px; }
    @media (min-width: 992px) {
      .cta_block .cta_link:hover .cta-text {
        padding-top: 50px; } }
    @media (min-width: 1400px) {
      .cta_block .cta_link:hover .cta-text {
        padding-top: 100px; } }
  .cta_block .cta_link .overlay,
  .cta_block .cta_link .cta-img:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    opacity: 0; }
  .cta_block .cta_link .cta-img {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat; }
    .cta_block .cta_link .cta-img:before {
      content: '';
      background-color: rgba(17, 17, 17, 0.3);
      opacity: 1; }
  .cta_block .cta_link .cta-text {
    position: relative;
    z-index: 5;
    padding-top: 100px; }
    @media (min-width: 992px) {
      .cta_block .cta_link .cta-text {
        padding-top: 70px; } }
    @media (min-width: 1400px) {
      .cta_block .cta_link .cta-text {
        padding-top: 150px; } }
  .cta_block .cta_link .cta-big-title {
    font-size: 28px;
    font-weight: bold;
    margin: 15px auto; }
  .cta_block .cta_link .cta-small-title {
    font-style: italic; }

.second_cta_block > .pad {
  padding: 20px;
  display: table;
  width: 100%;
  margin: 0 auto;
  max-width: 1200px; }
  @media (min-width: 992px) {
    .second_cta_block > .pad {
      padding: 40px 20px; } }

.second_cta_block .second-cta {
  text-align: center; }
  @media (min-width: 992px) {
    .second_cta_block .second-cta {
      padding: 0 5px; } }
  .second_cta_block .second-cta .border {
    max-width: 350px;
    padding-bottom: 15px;
    margin: 0 auto 15px;
    border-bottom: 1px dashed #d2d2d2; }
    @media (min-width: 768px) {
      .second_cta_block .second-cta .border {
        border: none;
        margin-bottom: 0;
        padding-bottom: 0; } }
    @media (min-width: 1400px) {
      .second_cta_block .second-cta .border {
        max-width: 355px; } }
  .second_cta_block .second-cta:last-child .border {
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: none; }
  .second_cta_block .second-cta img {
    display: none; }
    @media (min-width: 768px) {
      .second_cta_block .second-cta img {
        display: block; } }

.second_cta_block .cta-img {
  margin-bottom: 15px;
  position: relative; }
  .second_cta_block .cta-img:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(17, 17, 17, 0.4);
    display: none; }
    @media (min-width: 768px) {
      .second_cta_block .cta-img:after {
        display: block; } }
  .second_cta_block .cta-img .cta-icon {
    color: #111;
    font-size: 48px; }
    @media (min-width: 768px) {
      .second_cta_block .cta-img .cta-icon {
        color: #fff;
        text-align: center;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        z-index: 5; } }

.second_cta_block .cta-bottom .cta-big-title {
  color: #204b7a;
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 10px; }

.second_cta_block .cta-bottom .btnbox {
  margin: 20px auto; }

#footer_container {
  color: #fff;
  background-color: #303030; }

#footer {
  text-align: center;
  padding: 20px 0; }
  @media (min-width: 768px) {
    #footer {
      padding: 30px 0; } }
  @media (min-width: 992px) {
    #footer {
      text-align: left; } }
  @media (min-width: 1400px) {
    #footer {
      padding: 40px 0; } }
  @media (min-width: 1400px) {
    #footer .container {
      display: table;
      width: 100%;
      margin: 0 auto;
      max-width: 1350px; } }
  @media (min-width: 1600px) {
    #footer .container {
      max-width: 1500px; } }
  #footer ul {
    margin: 0;
    padding: 0;
    list-style: none; }
  #footer h3 {
    color: #5d8fc8;
    font-size: 18px;
    font-weight: 400;
    text-transform: uppercase;
    margin-bottom: 10px; }
  #footer a {
    color: #fff;
    display: block;
    text-decoration: none; }
  #footer a:hover {
    color: #5d8fc8; }
  #footer .ftr-box, #footer .logobox {
    margin-bottom: 20px; }
  #footer .ftr-info {
    font-size: 16px;
    font-weight: 300;
    max-width: 220px;
    display: table;
    margin: 0 auto 10px;
    width: 100%; }
    #footer .ftr-info .ftr-phone, #footer .ftr-info .ftr-address {
      text-align: left;
      display: table;
      margin: 0 auto 10px;
      width: 100%; }
    #footer .ftr-info .icon, #footer .ftr-info .text, #footer .ftr-info .mobile-only {
      display: inline-block;
      vertical-align: top; }
    #footer .ftr-info .icon {
      width: 15%;
      margin-right: 5%;
      font-size: 20px;
      text-align: right; }
    #footer .ftr-info .text {
      width: 80%; }
    #footer .ftr-info .desktop-only {
      display: none; }
      @media (min-width: 992px) {
        #footer .ftr-info .desktop-only {
          display: inline-block; } }
    @media (min-width: 992px) {
      #footer .ftr-info .mobile-only {
        display: none; } }
  #footer .ftr-social .social-title {
    color: #5d8fc8;
    font-size: 24px;
    margin-bottom: 10px; }
  #footer .ftr-social .smedia {
    display: table;
    margin: 0 auto; }
    #footer .ftr-social .smedia li {
      display: inline-block;
      width: 34px;
      height: 34px;
      line-height: 34px;
      margin: 0 3px; }
    #footer .ftr-social .smedia a {
      color: #303030;
      font-size: 20px;
      display: block;
      background-color: #ececec; }
      #footer .ftr-social .smedia a:hover {
        color: #ececec;
        background-color: #464646; }
  #footer .menu-box {
    display: none; }
    @media (min-width: 992px) {
      #footer .menu-box {
        float: left;
        width: 75%;
        display: block; } }
    @media (min-width: 1400px) {
      #footer .menu-box {
        width: 82%; } }
  @media (min-width: 992px) {
    #footer .ftr-box#box-1 {
      float: left;
      width: 25%;
      text-align: center; } }
  @media (min-width: 1400px) {
    #footer .ftr-box#box-1 {
      width: 18%; } }
  #footer .ftr-box#box-2, #footer .ftr-box#box-3, #footer .ftr-box#box-4, #footer .ftr-box#box-5, #footer .ftr-box#box-6, #footer .ftr-box#box-7 {
    display: inline-block;
    margin-left: -5px;
    width: 33.333%;
    vertical-align: top;
    display: inline-block; }
    @media (min-width: 1400px) {
      #footer .ftr-box#box-2, #footer .ftr-box#box-3, #footer .ftr-box#box-4, #footer .ftr-box#box-5, #footer .ftr-box#box-6, #footer .ftr-box#box-7 {
        width: 16%; } }
  @media (min-width: 992px) {
    #footer .ftr-box > .pad {
      padding: 0 10px;
      display: table;
      width: 100%; } }
  #footer .menu > li {
    float: left;
    text-align: left;
    line-height: 1;
    width: 100%;
    border-bottom: 1px solid rgba(236, 236, 236, 0.3);
    position: relative; }
  #footer .menu > li > a {
    font-size: 16px;
    font-weight: 300;
    padding: 12px 5px;
    display: block; }
  #footer .menu > li > a:before {
    content: '\f105';
    font-family: FontAwesome;
    margin-right: 10px; }
  #footer .textwidget {
    font-size: .9em;
    line-height: 1em; }
  #footer .widget_sp_image h3 {
    display: none; }
  #footer .widget_sp_image img {
    max-height: 70px;
    width: auto; }

#ftr_bottom {
  color: #fff;
  font-weight: 300;
  background-color: #111;
  padding: 10px 0; }
  @media (min-width: 768px) {
    #ftr_bottom {
      text-align: center; } }
  #ftr_bottom #site-info, #ftr_bottom #site-generator {
    text-align: center;
    font-size: .8em; }
    @media (min-width: 768px) {
      #ftr_bottom #site-info, #ftr_bottom #site-generator {
        display: inline-block;
        margin: 0 5px; } }
  #ftr_bottom a {
    color: #fff;
    text-decoration: none; }

.home-message {
  padding: 20px 0; }
  @media (min-width: 768px) {
    .home-message {
      padding: 30px 0; } }
  @media (min-width: 1400px) {
    .home-message {
      padding: 40px 0; } }
  .home-message.bible-study .image-block {
    padding-bottom: 20px; }
    @media (min-width: 768px) {
      .home-message.bible-study .image-block {
        padding-bottom: 0; } }
  .home-message .radiospot audio {
    width: 100%; }

.home-btnbox {
  text-align: center;
  display: table;
  max-width: 350px;
  margin: 0 auto 15px; }
  @media (min-width: 768px) {
    .home-btnbox {
      max-width: 100%; } }
  .home-btnbox .home-btn {
    display: inline-block;
    margin: 0 5px 10px; }
    .home-btnbox .home-btn a {
      color: #fff !important; }

.home-services > .pad {
  max-width: 1000px;
  margin: 0 auto; }

.home-services .service {
  margin-bottom: 15px; }

.home-services .service_link {
  color: #fff;
  text-align: center;
  display: block;
  height: 170px;
  text-decoration: none;
  overflow: hidden;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  position: relative;
  max-width: 370px;
  margin: 0 auto; }
  @media (min-width: 992px) {
    .home-services .service_link {
      height: 250px;
      max-width: 460px; } }
  @media (min-width: 1200px) {
    .home-services .service_link {
      height: 280px;
      max-width: 470px; } }
  .home-services .service_link:hover .service-img {
    -webkit-transform: scale(1.15);
    -o-transform: scale(1.15);
    -ms-transform: scale(1.15);
    -moz-transform: scale(1.15);
    transform: scale(1.15); }
  .home-services .service_link:hover .servicebox {
    padding: 50px 20px 0; }
    @media (min-width: 992px) {
      .home-services .service_link:hover .servicebox {
        padding-top: 95px; } }
  .home-services .service_link:hover .service-text {
    opacity: 1;
    margin-top: 10px; }
  .home-services .service_link:hover .service-img:before {
    background-color: rgba(17, 17, 17, 0.8); }
  .home-services .service_link .service-img {
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease;
    z-index: 1;
    position: relative; }
    .home-services .service_link .service-img:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(17, 17, 17, 0.6);
      -webkit-transition: all 0.4s ease;
      -o-transition: all 0.4s ease;
      -ms-transition: all 0.4s ease;
      -moz-transition: all 0.4s ease;
      transition: all 0.4s ease;
      z-index: 2; }
  .home-services .service_link .servicebox {
    padding: 75px 20px 0;
    position: absolute;
    z-index: 5;
    width: 100%;
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease; }
    @media (min-width: 992px) {
      .home-services .service_link .servicebox {
        padding-top: 115px; } }
    .home-services .service_link .servicebox .valign {
      display: table;
      width: 100%;
      max-width: 320px;
      margin: 0 auto; }
  .home-services .service_link .service-title {
    font-size: 28px; }
    @media (min-width: 992px) {
      .home-services .service_link .service-title {
        font-size: 32px; } }
  .home-services .service_link .service-text {
    color: #ececec;
    font-size: 14px;
    font-weight: 300;
    margin-top: 10px;
    opacity: 0;
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease; }
    @media (min-width: 992px) {
      .home-services .service_link .service-text {
        font-size: 16px; } }

.gridrow .gridboxes {
  text-align: center;
  height: 50vw;
  margin-bottom: 15px; }
  @media (min-width: 600px) {
    .gridrow .gridboxes {
      height: 45vw; } }
  @media (min-width: 768px) {
    .gridrow .gridboxes {
      height: 20vw;
      max-height: 390px; } }
  @media (min-width: 1200px) {
    .gridrow .gridboxes {
      height: 18vw; } }
  @media (min-width: 1600px) {
    .gridrow .gridboxes {
      height: 15vw; } }
  @media (min-width: 1800px) {
    .gridrow .gridboxes {
      height: 12vw; } }
  .gridrow .gridboxes * {
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease; }
  .gridrow .gridboxes a {
    text-decoration: none;
    display: block;
    width: 100%;
    height: 100%;
    padding: 0 10px;
    margin: 0 auto;
    position: relative;
    background-color: #111;
    overflow: hidden;
    max-width: 520px;
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease; }
  .gridrow .gridboxes .gridtext {
    color: #fff;
    line-height: 1.1;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    z-index: 5;
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease; }
    .gridrow .gridboxes .gridtext .grid-btn-text {
      font-size: 22px;
      padding-top: 20px;
      margin-bottom: 5px; }
      @media (min-width: 992px) {
        .gridrow .gridboxes .gridtext .grid-btn-text {
          font-size: 24px; } }
      @media (min-width: 1200px) {
        .gridrow .gridboxes .gridtext .grid-btn-text {
          font-size: 28px; } }
    .gridrow .gridboxes .gridtext .grid-sub-text {
      font-size: 18px;
      font-weight: 300;
      opacity: 0;
      /*@include lg {font-size: 24px;}*/ }
      @media (min-width: 992px) {
        .gridrow .gridboxes .gridtext .grid-sub-text {
          font-size: 20px; } }
  .gridrow .gridboxes .grid-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: none;
    background-size: cover;
    background-position: center;
    z-index: 1;
    opacity: 1;
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease; }
    .gridrow .gridboxes .grid-img:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(17, 17, 17, 0.6);
      -webkit-transition: all 0.4s ease;
      -o-transition: all 0.4s ease;
      -ms-transition: all 0.4s ease;
      -moz-transition: all 0.4s ease;
      transition: all 0.4s ease;
      z-index: 2; }
  .gridrow .gridboxes:hover .gridtext .grid-btn-text {
    padding-top: 0; }
  .gridrow .gridboxes:hover .gridtext .grid-sub-text {
    padding-top: 0;
    opacity: 1; }
  .gridrow .gridboxes:hover .grid-img {
    transform: scale(1.15); }
    .gridrow .gridboxes:hover .grid-img:before {
      background-color: rgba(17, 17, 17, 0.8); }

#hdr {
  position: relative;
  z-index: 99; }

#hdr_top {
  font-size: 16px;
  height: 0;
  background-color: #ececec; }
  @media (min-width: 768px) {
    #hdr_top {
      height: auto; } }

#hdr_nav {
  background-color: #111; }
  @media (min-width: 768px) {
    #hdr_nav {
      padding: 8px 0; } }
  @media (min-width: 992px) {
    #hdr_nav {
      padding: 0;
      background: none;
      display: table;
      margin: 0 0 0 auto; } }
  #hdr_nav ul {
    margin: 0;
    padding: 0;
    list-style: none; }
  #hdr_nav a {
    display: block; }
  #hdr_nav .social {
    float: left;
    text-align: center;
    height: 30px;
    width: 30px;
    line-height: 30px;
    margin: 0 4px; }
  #hdr_nav .social a {
    color: #fff;
    font-size: 1.3em;
    background-color: #204b7a;
    width: 100%;
    border-radius: 30px; }
  #hdr_nav .social a:hover {
    background-color: #8b2d26; }

#hdr_bottom {
  padding: 8px 0;
  background-color: rgba(255, 255, 255, 0.9);
  position: relative;
  z-index: 99; }
  @media (min-width: 768px) {
    #hdr_bottom {
      padding: 0; } }
  #hdr_bottom .logo {
    float: left;
    margin: 0 auto; }
    @media (min-width: 768px) {
      #hdr_bottom .logo {
        padding: 10px 0; } }
  #hdr_bottom .logo img {
    max-height: 70px;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease; }
    @media (min-width: 768px) {
      #hdr_bottom .logo img {
        max-height: 65px; } }
  #hdr_bottom #trigger {
    color: #204b7a;
    float: right;
    font-size: 30px;
    line-height: 70px; }
    @media (min-width: 768px) {
      #hdr_bottom #trigger {
        line-height: 85px; } }
  #hdr_bottom #trigger span {
    font-size: 18px;
    text-transform: uppercase; }
  #hdr_bottom.sticky {
    position: fixed;
    top: 0;
    border-bottom: 1px solid #ececec; }
    #hdr_bottom.sticky .logo img {
      max-height: 50px; }
    #hdr_bottom.sticky #trigger {
      line-height: 50px; }
  #hdr_bottom .top_phone {
    float: left;
    width: 80%; }
    @media (min-width: 768px) {
      #hdr_bottom .top_phone {
        float: right;
        width: auto;
        margin-top: 20px; } }
  #hdr_bottom .phone {
    color: #204b7a;
    font-size: 1.6em;
    font-weight: 600;
    line-height: 30px;
    text-align: left; }
    #hdr_bottom .phone a {
      color: #464646;
      text-decoration: none; }
  #hdr_bottom .slogan {
    color: #464646;
    float: left;
    text-transform: uppercase;
    font-size: .9em;
    font-weight: bold;
    margin-top: 30px; }
    @media (min-width: 768px) {
      #hdr_bottom .slogan {
        margin-left: 30px; } }

#mmenu.mm-menu {
  z-index: 999;
  background: #204b7a; }
  #mmenu.mm-menu .mm-panel a {
    color: #ececec; }
  #mmenu.mm-menu .mm-panel#mm-1 {
    padding-top: 0; }
  #mmenu.mm-menu .mm-panel#mm-1 > .mm-navbar {
    display: none; }
  #mmenu.mm-menu em.mm-counter {
    color: inherit; }
  #mmenu.mm-menu .mm-listview > li:not(.mm-divider):after {
    left: 0; }
  #mmenu.mm-menu .mm-listview > li, #mmenu.mm-menu .mm-listview > li .mm-next,
  #mmenu.mm-menu .mm-listview > li .mm-next:before,
  #mmenu.mm-menu .mm-listview > li:after,
  #mmenu.mm-menu .mm-listview > li .mm-next:after,
  #mmenu.mm-menu .mm-navbar, #mmenu.mm-menu .mm-btn:after,
  #mmenu.mm-menu .mm-btn:before {
    border-color: rgba(236, 236, 236, 0.5); }
  #mmenu.mm-menu .menu a, #mmenu.mm-menu .mm-navbar > * {
    color: #ececec;
    font-size: 18px;
    font-weight: 300; }

@media (min-width: 768px) {
  .nav_top {
    float: right; } }

.nav_top ul {
  margin: 0 auto;
  padding: 0;
  list-style: none;
  text-align: center;
  display: table; }

.nav_top ul > li {
  float: left;
  line-height: 40px; }

.nav_top ul > li > a {
  color: #464646;
  font-weight: 400;
  padding: 0 10px;
  text-decoration: none;
  display: block; }

.nav_top ul > li > a:hover {
  background-color: #fff; }

#main_nav {
  float: right;
  position: relative; }

.mainNav {
  /*.menu > li:last-child > .sub-menu {
		left:auto;
		right:0;
	}*/
  /*.sub-menu > li {line-height: 34px;}*/ }
  .mainNav ul {
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center; }
  .mainNav .menu > li {
    float: left;
    height: 85px;
    line-height: 85px;
    margin: 0;
    position: relative; }
    .mainNav .menu > li.two-column {
      position: initial; }
      .mainNav .menu > li.two-column > .sub-menu {
        text-align: center; }
      .mainNav .menu > li.two-column > .sub-menu > li {
        display: inline-block;
        vertical-align: top;
        line-height: initial;
        width: 50%;
        margin-left: -5px; }
      .mainNav .menu > li.two-column > .sub-menu > li > a {
        font-weight: 600;
        line-height: 30px; }
    .mainNav .menu > li > a {
      color: #464646;
      font-size: 20px;
      font-weight: 400;
      padding: 0 20px;
      border-radius: 0;
      height: 100%; }
      @media (min-width: 1200px) {
        .mainNav .menu > li > a {
          padding: 0 20px; } }
    .mainNav .menu > li > .sub-menu {
      opacity: 0;
      top: 95px;
      left: 0;
      width: 100%;
      padding: 10px 15px 15px;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
      visibility: hidden;
      background-color: #fff; }
    .mainNav .menu > li:nth-child(4) > .sub-menu,
    .mainNav .menu > li:last-child > .sub-menu {
      left: auto;
      right: 0; }
    .mainNav .menu > li:hover > .sub-menu {
      opacity: 1;
      top: 85px;
      visibility: visible; }
  .mainNav .menu a {
    display: block;
    text-decoration: none;
    position: relative;
    -webkit-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease; }
  .mainNav .menu > li > a:hover,
  .mainNav .menu > li.current-menu-item > a,
  .mainNav .menu > li.current-menu-parent > a {
    color: #204b7a;
    background-color: #fff; }
  .mainNav .menu > li:hover > a:after,
  .mainNav .menu > li.current-menu-item > a:after,
  .mainNav .menu > li.current-menu-parent > a:after {
    content: '';
    position: absolute;
    height: 3px;
    width: 100%;
    background-color: #8b2d26;
    bottom: 0;
    left: 0;
    -webkit-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease; }
  .mainNav .sub-menu {
    position: absolute;
    min-width: 200px;
    z-index: 999;
    text-align: left;
    -webkit-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease; }
    .mainNav .sub-menu li {
      text-align: left;
      line-height: 30px; }
    .mainNav .sub-menu > li > a {
      color: #204b7a;
      font-size: 16px;
      font-weight: 300;
      padding: 0 10px;
      white-space: nowrap; }
    .mainNav .sub-menu > li > a:hover {
      color: #fff;
      background-color: #204b7a; }
    .mainNav .sub-menu .sub-menu {
      position: relative; }

.sticky .mainNav .menu > li {
  height: 70px;
  line-height: 70px; }

.sticky .mainNav .menu > li:hover > .sub-menu {
  top: 70px; }

.icons {
  padding: 0 15px;
  margin-top: 20px; }
  .icons > .pad {
    padding: 20px 0 0;
    display: table;
    width: 100%;
    background-color: #ececec; }
  .icons .innerbox {
    max-width: 400px;
    margin: 0 auto; }
    @media (min-width: 768px) {
      .icons .innerbox {
        max-width: 700px; } }
    @media (min-width: 992px) {
      .icons .innerbox {
        max-width: 850px; } }
  .icons .nextbox {
    text-align: center;
    float: left;
    vertical-align: top;
    width: 25%; }
    .icons .nextbox.fbox {
      font-size: 30px;
      font-weight: bold;
      width: 100%;
      padding: 0; }
      .icons .nextbox.fbox > div {
        display: inline-block;
        vertical-align: middle; }
      .icons .nextbox.fbox .next-icon {
        height: auto;
        width: auto;
        background-color: transparent;
        display: inline-block; }
    .icons .nextbox.cross {
      margin-left: 0; }
    .icons .nextbox a {
      color: #303030;
      display: block;
      text-decoration: none;
      padding: 20px 10px;
      line-height: 1;
      text-transform: uppercase; }
      .icons .nextbox a:hover {
        color: #204b7a !important;
        background-color: #fff; }
    .icons .nextbox .next-icon {
      font-size: 30px;
      height: 60px;
      width: 60px;
      margin: 0 auto 15px;
      background-color: #fff;
      border-radius: 60%;
      display: table; }
      .icons .nextbox .next-icon .fa {
        display: table-cell;
        vertical-align: middle; }
  .icons.cross-current .cross a {
    color: #197B5D;
    background-color: #fff; }
  .icons.church-new-current .church-new a {
    color: #197B5D;
    background-color: #fff; }
  .icons.hand-current .hand a {
    color: #197B5D;
    background-color: #fff; }
  .icons.bible-current .bible a {
    color: #197B5D;
    background-color: #fff; }

.salt #hdr_bottom .logo img {
  -webkit-filter: hue-rotate(130deg);
  filter: hue-rotate(130deg); }

.salt .banner,
.salt .mainNav .sub-menu > li > a:hover {
  background-color: #8b2d26; }

.salt .mainNav .menu > li > a:hover,
.salt .mainNav .sub-menu > li > a,
.salt #footer h3, .salt .salt-content a,
.salt #footer .ftr-social .social-title {
  color: #8b2d26; }

.salt .mainNav .sub-menu > li > a:hover {
  color: #fff; }

#need-prayer {
  color: #fff;
  text-align: center;
  margin: 20px 0; }
  #need-prayer .prayerImg {
    background-position: right top;
    background-size: cover;
    background-repeat: no-repeat; }
    @media (min-width: 992px) {
      #need-prayer .prayerImg {
        background-position: left top; } }
  #need-prayer .pad {
    padding: 30px 20px; }
    @media (min-width: 768px) {
      #need-prayer .pad {
        padding: 40px 20px;
        max-width: 450px;
        margin: 0 0 0 auto; } }
    @media (min-width: 992px) {
      #need-prayer .pad {
        padding: 70px 20px;
        max-width: 500px;
        margin-right: 40px; } }
    @media (min-width: 1200px) {
      #need-prayer .pad {
        padding: 90px 60px 90px 20px;
        max-width: 600px; } }
    @media (min-width: 1400px) {
      #need-prayer .pad {
        padding: 110px 60px 110px 20px;
        margin-right: 60px; } }
    @media (min-width: 1600px) {
      #need-prayer .pad {
        margin-right: 25%; } }
  #need-prayer h3 {
    color: #fff;
    text-align: center; }
    @media (min-width: 1200px) {
      #need-prayer h3 {
        font-size: 34px;
        margin-bottom: 30px; } }
  #need-prayer p {
    line-height: 1.2em;
    margin-bottom: 15px; }
    @media (min-width: 992px) {
      #need-prayer p {
        line-height: 1.4em; } }
    @media (min-width: 1200px) {
      #need-prayer p {
        font-size: 20px;
        font-weight: 300;
        line-height: 1.4em;
        margin-bottom: 30px; } }
  #need-prayer .btn {
    color: #fff;
    background-color: #204b7a;
    border: none; }
    #need-prayer .btn:hover {
      color: #464646;
      background-color: #fff; }

form#contactform input[type="text"] {
  width: 100%;
  border: 1px solid #f5f5f5;
  min-height: 40px;
  padding-left: 20px;
  font-size: 13px;
  padding-right: 20px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

form#contactform textarea {
  border: 1px solid #f5f5f5;
  width: 100%;
  padding-left: 20px;
  padding-top: 10px;
  font-size: 13px;
  padding-right: 20px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

form#contactform .validation {
  font-size: 11px; }

#sendmessage {
  border: 1px solid #e6e6e6;
  background: #f6f6f6;
  display: none;
  text-align: center;
  padding: 15px 12px 15px 65px;
  margin: 10px 0;
  font-weight: 600;
  margin-bottom: 30px; }

#sendmessage.show, .show {
  display: block; }

form#commentform input[type="text"] {
  width: 100%;
  min-height: 40px;
  padding-left: 20px;
  font-size: 13px;
  padding-right: 20px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-border-radius: 2px 2px 2px 2px;
  -moz-border-radius: 2px 2px 2px 2px;
  border-radius: 2px 2px 2px 2px; }

form#commentform textarea {
  width: 100%;
  padding-left: 20px;
  padding-top: 10px;
  font-size: 13px;
  padding-right: 20px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-border-radius: 2px 2px 2px 2px;
  -moz-border-radius: 2px 2px 2px 2px;
  border-radius: 2px 2px 2px 2px; }

.resources-list {
  overflow-x: scroll; }
  .resources-list .resource {
    text-align: center;
    position: relative;
    overflow: hidden; }
    @media (min-width: 992px) {
      .resources-list .resource {
        margin-bottom: 15px; } }
    .resources-list .resource .imgbox {
      position: relative;
      overflow: hidden;
      background-color: #111;
      margin-bottom: 15px; }
      .resources-list .resource .imgbox a {
        color: #fff !important;
        display: block; }
      .resources-list .resource .imgbox .resource-pad {
        position: absolute;
        width: 100%;
        height: 80px;
        bottom: 0;
        background-color: rgba(17, 17, 17, 0.7);
        z-index: 3;
        -webkit-transition: all 0.4s ease;
        -o-transition: all 0.4s ease;
        -ms-transition: all 0.4s ease;
        -moz-transition: all 0.4s ease;
        transition: all 0.4s ease; }
      .resources-list .resource .imgbox .resource-title {
        font-size: 18px;
        line-height: 1;
        padding: 30px 0;
        text-transform: uppercase; }
        @media (min-width: 768px) {
          .resources-list .resource .imgbox .resource-title {
            font-size: 24px; } }
      .resources-list .resource .imgbox .valign {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        -webkit-transition: all 0.4s ease;
        -o-transition: all 0.4s ease;
        -ms-transition: all 0.4s ease;
        -moz-transition: all 0.4s ease;
        transition: all 0.4s ease; }
      .resources-list .resource .imgbox img {
        position: relative;
        -webkit-transition: all 0.4s ease;
        -o-transition: all 0.4s ease;
        -ms-transition: all 0.4s ease;
        -moz-transition: all 0.4s ease;
        transition: all 0.4s ease;
        z-index: 1; }
    .resources-list .resource:hover .resource-pad {
      height: 100%; }
    .resources-list .resource:hover img {
      -webkit-transform: scale(1.15);
      -o-transform: scale(1.15);
      -ms-transform: scale(1.15);
      -moz-transform: scale(1.15);
      transform: scale(1.15);
      opacity: 0.6; }

#searchToggle {
  color: #464646;
  float: right;
  display: block;
  padding: 0 15px;
  line-height: 40px; }
  #searchToggle:hover {
    color: #111; }

.site_search_wrap {
  display: none;
  width: 100%;
  background-color: rgba(32, 75, 122, 0.9);
  position: absolute;
  top: 125px;
  z-index: 99; }
  .site_search_wrap .pad {
    padding: 60px 40px 30px; }
    @media (min-width: 992px) {
      .site_search_wrap .pad {
        padding: 60px 40px 40px; } }
  .site_search_wrap .close_div {
    color: #fff;
    text-align: center;
    position: absolute;
    right: 20px;
    top: 10px;
    display: table;
    width: 30px;
    height: 30px;
    line-height: 30px;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    border: 1px solid #fff;
    -webkit-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease; }
  .site_search_wrap .close_div:hover {
    opacity: 0.7; }
  .site_search_wrap .site_search {
    width: 100%;
    margin: 0 auto;
    display: block;
    position: relative;
    max-width: 1000px; }
  .site_search_wrap .site_search label {
    color: #204b7a;
    font-weight: 300;
    position: absolute;
    top: 22px;
    left: 21px;
    -webkit-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease; }
  .site_search_wrap .site_search.active label {
    opacity: 0.5; }
  .site_search_wrap .site_search a.site_search_clear {
    display: block;
    position: absolute;
    top: 27px;
    right: 90px; }
  .site_search_wrap .site_search input[type="text"] {
    width: 100%;
    border: 0;
    height: 60px;
    padding: 20px;
    background-color: #fff;
    border: 1px solid #fff; }
    @media (min-width: 768px) {
      .site_search_wrap .site_search input[type="text"] {
        height: 70px; } }
  .site_search_wrap .site_search input[type="text"]:focus {
    outline: -webkit-focus-ring-color auto 1px; }
  .site_search_wrap .site_search .search_submit {
    color: #fff;
    background-color: #8b2d26;
    width: 60px;
    height: 60px;
    position: absolute;
    right: 0;
    top: 0;
    line-height: 60px;
    text-align: center;
    font-size: 30px; }
    @media (min-width: 768px) {
      .site_search_wrap .site_search .search_submit {
        width: 70px;
        height: 70px;
        line-height: 70px; } }

.sticky .site_search_wrap {
  top: 106px; }

.sermon {
  text-align: center;
  margin-bottom: 15px; }
  .sermon > .pad {
    padding: 20px 10px;
    border: 1px dashed #464646; }
  .sermon .sermon-title {
    font-size: 1.3em;
    font-weight: 600;
    margin-bottom: 10px; }
  .sermon .sermon-date {
    font-size: 1.1em;
    margin-bottom: 8px; }
  .sermon a.sermon-link {
    text-decoration: none;
    display: inline-block;
    padding: 0 5px; }
    .sermon a.sermon-link:hover {
      color: #8b2d26; }
    .sermon a.sermon-link .pdf-icon {
      color: #8b2d26; }
    .sermon a.sermon-link .ppt-icon {
      color: #197B5D; }

.sidebar {
  width: 25%;
  display: table-cell;
  vertical-align: top;
  background-color: #ececec; }
  .sidebar ul {
    margin: 0 0 20px;
    padding: 0;
    list-style: none; }
  .sidebar .saltsidebar {
    padding-top: 30px; }
  .sidebar .menu a {
    text-decoration: none; }
  .sidebar .menu > li {
    border-bottom: 1px solid rgba(70, 70, 70, 0.3); }
    .sidebar .menu > li > a {
      color: #8b2d26;
      font-size: 18px;
      font-weight: 500;
      padding: 10px 15px;
      display: block;
      line-height: 1; }
      .sidebar .menu > li > a:hover {
        color: #fff;
        background-color: #8b2d26; }
    .sidebar .menu > li.current-menu-item a {
      color: #fff;
      background-color: #8b2d26; }
    .sidebar .menu > li:first-child a {
      color: #fff;
      font-size: 20px;
      padding: 20px 15px;
      text-transform: uppercase;
      background-color: #8b2d26; }
      .sidebar .menu > li:first-child a:hover {
        color: #fff;
        background-color: #204b7a; }
    .sidebar .menu > li:first-child.current-page-parent a {
      color: #fff;
      background-color: #204b7a; }
      .sidebar .menu > li:first-child.current-page-parent a:hover {
        color: #fff;
        background-color: #8b2d26; }

.image_hero.sticky {
  margin-top: 86px; }

.heroBG {
  background-size: cover;
  background-position: center;
  height: 400px;
  display: table;
  width: 100%;
  position: relative; }
  @media (min-width: 768px) {
    .heroBG {
      height: 500px; } }
  .heroBG.mobile-hero {
    height: 250px; }
    @media (min-width: 768px) {
      .heroBG.mobile-hero {
        height: 310px; } }
    @media (min-width: 1200px) {
      .heroBG.mobile-hero {
        height: 450px; } }
  .heroBG .img_hero_content {
    color: #fff;
    max-width: 500px;
    margin: 0 auto;
    display: table-cell;
    vertical-align: middle;
    text-transform: uppercase;
    position: relative;
    z-index: 5; }
    .heroBG .img_hero_content .pad {
      padding: 20px 0px; }
  .heroBG .hero_content {
    max-width: 380px;
    margin: 50px auto 0;
    text-align: center;
    display: table;
    transform: scale(0.9); }
    @media (min-width: 768px) {
      .heroBG .hero_content {
        margin-top: 170px;
        max-width: 420px;
        transform: scale(1); } }
  @media (min-width: 768px) {
    .heroBG .sm_text {
      font-size: 1.3em; } }
  .heroBG .lg_text {
    font-size: 2.5em;
    line-height: 1em;
    border-top: 1px solid #fff;
    padding-top: 3px;
    margin-top: 3px; }
    @media (min-width: 768px) {
      .heroBG .lg_text {
        font-size: 3.2em; } }
  .heroBG .xlg_text {
    font-size: 4.7em;
    line-height: .9em;
    border-bottom: 1px solid #fff;
    padding-bottom: 3px;
    margin-bottom: 3px; }
    @media (min-width: 768px) {
      .heroBG .xlg_text {
        font-size: 6em; } }
  .heroBG h2, .heroBG h3 {
    color: #fff;
    font-size: 1.5em;
    margin: 0 0 20px; }
  .heroBG h3 {
    font-size: 1.2em;
    font-weight: 600; }
  .heroBG .btnbox {
    width: 100%;
    text-align: center; }

.banner {
  color: #fff;
  text-align: center;
  height: 130px;
  background-color: #204b7a; }
  @media (min-width: 768px) {
    .banner {
      font-size: 32px;
      line-height: 34px; } }
  @media (min-width: 992px) {
    .banner {
      height: 160px; } }
  .banner .container {
    height: 100%;
    position: relative; }
  .banner .banner-text {
    max-width: 600px;
    margin: 0 auto;
    position: relative;
    top: 50%;
    transform: translateY(-50%); }
    .banner .banner-text .banner-title {
      font-size: 26px;
      line-height: 30px; }
    .banner .banner-text .sub-title {
      font-family: 'Roboto Condensed', sans-serif;
      font-size: 16px;
      font-weight: 300;
      line-height: 1.2; }
      @media (min-width: 992px) {
        .banner .banner-text .sub-title {
          font-size: 18px; } }

.top_social {
  float: left; }
  .top_social .smedia {
    margin: 0;
    padding: 0;
    list-style: none; }
    .top_social .smedia li {
      float: left;
      height: 40px;
      line-height: 40px; }
    .top_social .smedia a {
      color: #464646;
      font-size: 18px;
      padding: 0 10px;
      display: block; }
      .top_social .smedia a:hover {
        background-color: #fff; }

.staff-row {
  text-align: center; }
  .staff-row .staff-box {
    width: 100%;
    display: inline-block;
    padding: 0 30px 30px;
    background-color: #ececec;
    border: 1px solid #d2d2d2;
    min-height: 250px;
    max-width: 335px;
    margin: 110px auto 25px; }
    @media (min-width: 768px) {
      .staff-row .staff-box {
        margin: 110px 10px 25px; } }
    .staff-row .staff-box .staff-img {
      display: block;
      margin: -100px auto 0; }
      .staff-row .staff-box .staff-img img {
        height: 200px;
        border: 1px solid #d2d2d2;
        max-width: 100%;
        border-radius: 50%; }
    .staff-row .staff-box .staff-name, .staff-row .staff-box .staff-title,
    .staff-row .staff-box .staff-social {
      text-align: left; }
    .staff-row .staff-box .staff-name {
      font-size: 20px;
      font-size: 2rem;
      line-height: 1;
      font-weight: 600;
      margin: 20px 0 10px; }
    .staff-row .staff-box .staff-title {
      font-size: 15px;
      font-size: 1.5rem;
      line-height: 1;
      margin-bottom: 10px; }
    .staff-row .staff-box .staff-social {
      margin: 0;
      padding: 0;
      list-style: none; }
      .staff-row .staff-box .staff-social li {
        float: left;
        margin-right: 5px; }
        .staff-row .staff-box .staff-social li.staff-facebook {
          background-color: #365698; }
        .staff-row .staff-box .staff-social li.staff-twitter {
          background-color: #54cdf4; }
        .staff-row .staff-box .staff-social li.staff-instagram {
          background-color: #396ea1; }
        .staff-row .staff-box .staff-social li.staff-youtube {
          background-color: #d8322f; }
        .staff-row .staff-box .staff-social li a {
          color: #fff !important;
          text-align: center;
          font-size: 20px;
          line-height: 30px;
          display: block;
          height: 30px;
          width: 30px; }

#content a.donate_now {
  width: 229px;
  height: 60px;
  float: left;
  margin-bottom: 10px;
  background: url("images/donate_now.png") left top no-repeat; }

#content a.donate_now:hover {
  background-position: left -60px; }

#content a.donate_now:active {
  background-position: left bottom; }

#grid_view_products_page_container {
  text-align: center; }
  #grid_view_products_page_container .wpsc-breadcrumbs {
    text-align: left;
    margin-bottom: 20px; }

.product_grid_display {
  display: table;
  margin: 0 auto; }
  .product_grid_display .product_grid_item {
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    border: 2px solid #ececec !important;
    padding: 10px;
    margin: 5px !important;
    display: inline-block;
    vertical-align: top;
    text-align: center;
    position: relative;
    height: 250px;
    width: calc(50% - 10px);
    -webkit-transition: all 0.25s ease;
    -o-transition: all 0.25s ease;
    -ms-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    transition: all 0.25s ease; }
    @media (min-width: 768px) {
      .product_grid_display .product_grid_item {
        margin: 0 5px 10px !important; } }
    @media (min-width: 992px) {
      .product_grid_display .product_grid_item {
        width: calc(25% - 20px);
        margin: 0 10px 10px !important; } }
    .product_grid_display .product_grid_item:hover {
      background: rgba(236, 236, 236, 0.6); }
  .product_grid_display .item_image a a {
    display: block;
    height: 148px;
    width: 148px;
    margin: 0 auto; }
  .product_grid_display .grid_product_info {
    padding: 0 !important; }

h2.prodtitles {
  font-size: 1.3em;
  display: block;
  width: 100%;
  float: left; }

#sideshoppingcart {
  display: block;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease; }
  @media (min-width: 1200px) {
    #sideshoppingcart {
      float: left;
      width: 50%; } }
  #sideshoppingcart.loaded {
    display: block; }
    @media (min-width: 1200px) {
      #sideshoppingcart.loaded {
        float: left;
        width: 50%; } }
  #sideshoppingcart .shopping-cart-wrapper .remove_button {
    background: url(../images/circle-x.png) no-repeat scroll 0 4px transparent;
    background-size: contain; }

.checkout_cart .header th {
  padding: 10px !important; }

.checkout_cart .product_row td.wpsc_product_name {
  width: auto !important; }

.checkout_cart .product_row form input {
  display: block;
  margin: 5px auto !important; }

.checkout_cart .product_row form input[type="submit"] {
  float: none !important; }

.checkout_cart .wpsc_coupon_row form {
  padding-right: 0 !important; }
  .checkout_cart .wpsc_coupon_row form input#coupon_num {
    width: 100% !important;
    max-width: 140px;
    margin: auto 10px auto auto !important; }

@media only screen and (max-width: 760px), (min-device-width: 768px) and (max-device-width: 1024px) {
  .checkout_cart {
    /* Force table to not be like tables anymore */
    /*
		Label the data
    You could also use a data-* attribute and content for this. That way "bloats" the HTML, this way means you need to keep HTML and CSS in sync. Lea Verou has a clever way to handle with text-shadow.
		*/ } }

.single_product_display {
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease; }
  @media (min-width: 1200px) {
    .single_product_display {
      float: left;
      width: 50%; } }
  .single_product_display .imagecol {
    width: 45%; }
    @media (min-width: 768px) {
      .single_product_display .imagecol {
        width: 25%; } }
  .single_product_display .product_description {
    margin: 0; }
  .single_product_display .product_form {
    float: left;
    width: 50%; }
    .single_product_display .product_form > fieldset, .single_product_display .product_form .wpsc_product_price {
      float: left;
      margin: 0 5px;
      width: calc(50% - 10px);
      max-width: 150px; }
    .single_product_display .product_form .wpsc_product_price {
      padding: 0 !important; }
      .single_product_display .product_form .wpsc_product_price label {
        font-size: 18px;
        font-weight: normal;
        display: block;
        width: 100%;
        padding: 0;
        margin-bottom: 20px;
        font-size: 21px;
        line-height: inherit;
        color: #333;
        border: 0;
        border-bottom: 1px solid #e5e5e5; }
    .single_product_display .product_form .wpsc_buy_button_container {
      width: 100%;
      float: left;
      text-align: left !important;
      margin-top: 15px; }
      .single_product_display .product_form .wpsc_buy_button_container .wpsc_buy_button {
        font-size: 1em !important;
        padding: 6px 25px !important; }
        @media (min-width: 992px) {
          .single_product_display .product_form .wpsc_buy_button_container .wpsc_buy_button {
            padding: 10px 30px !important; } }

.wpsc_page_numbers_bottom {
  margin-top: 20px; }
  .wpsc_page_numbers_bottom span, .wpsc_page_numbers_bottom a {
    display: inline-block;
    padding: 0px 9px;
    margin-right: 4px;
    border-radius: 3px;
    border: solid 1px #c0c0c0;
    background: #e9e9e9;
    box-shadow: inset 0px 1px 0px rgba(255, 255, 255, 0.8), 0px 1px 3px rgba(0, 0, 0, 0.1);
    font-size: .875em;
    font-weight: bold;
    text-decoration: none;
    color: #717171;
    text-shadow: 0px 1px 0px white; }
    .wpsc_page_numbers_bottom span.current, .wpsc_page_numbers_bottom a.current {
      border: none;
      background: #616161;
      box-shadow: inset 0px 0px 8px rgba(0, 0, 0, 0.5), 0px 1px 0px rgba(255, 255, 255, 0.8);
      color: #f0f0f0;
      text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5); }
    .wpsc_page_numbers_bottom span:hover, .wpsc_page_numbers_bottom a:hover {
      background: #fefefe;
      background: -webkit-gradient(linear, 0% 0%, 0% 100%, from(#FEFEFE), to(#f0f0f0));
      background: -moz-linear-gradient(0% 0% 270deg, #FEFEFE, #f0f0f0); }

.price_container {
  float: left; }

.product_grid_display a.more_details:link, .product_grid_display a.more_details:visited {
  margin: 0 !important; }

#grid_view_products_page_container .grid_more_info .quantity_container,
.wpsc_page_numbers_top {
  display: none; }

#grid_view_products_page_container .wpsc_category_grid_item img {
  width: 148px !important;
  height: 148px !important; }

.wpsc_category_details img,
.tax-wpsc_product_category.term-single img.post-image,
.tax-wpsc_product_category.term-series img.post-image {
  display: none; }

#content .entry-content .post-image {
  padding: 4px;
  border: 1px solid #E6E6E6; }

#content .post-image {
  margin: 0 10px 10px 0;
  padding: 4px;
  border: 1px solid #E6E6E6; }

#content .archive-page {
  float: left;
  width: 45%;
  margin: 0;
  padding: 20px 20px 0 0; }

.donate form input#donateForm form input#donateForm {
  border: 0;
  cursor: pointer;
  /*background: url("images/donate.png") top left no-repeat transparent;*/
  width: 102px;
  height: 36px;
  display: block;
  margin: 10px 0;
  text-indent: -9009px; }
  .donate form input#donateForm form input#donateForm:hover {
    background-position: left -35px; }
  .donate form input#donateForm form input#donateForm:active {
    background-position: left bottom; }

.product_grid_display .wpsc_buy_button, #content .product_grid_display .wpsc_buy_button
.blueBtn,
.vfb-submit,
#content .wpsc-transaction-results-wrap a {
  background: #204b7a !important;
  border-color: #204b7a !important;
  text-shadow: none !important; }
  .product_grid_display .wpsc_buy_button:hover, #content .product_grid_display .wpsc_buy_button
.blueBtn:hover,
  .vfb-submit:hover,
  #content .wpsc-transaction-results-wrap a:hover {
    color: #fff !important;
    background-color: #003459 !important; }

#content h2.prodtitle {
  font-size: 14px !important;
  margin: 5px 0;
  height: 35px;
  text-align: center !important;
  line-height: 16px !important; }

#content .grid_product_info p {
  margin-bottom: 5px;
  color: #777; }

#shopping_cart {
  float: left;
  width: 100%;
  /* background-color: #fff; */ }

#primary #shopping_cart a {
  padding: 0;
  font-size: 12px;
  text-transform: none !important; }

#primary #shopping_cart a:hover {
  color: #295183;
  text-decoration: underline;
  background: none; }

#shopping_cart h3 {
  margin: 0;
  color: #295183;
  font-size: 1.70em;
  font-weight: bold; }

#widgetshoppingcart,
#sideshoppingcart {
  padding: 7px 15px; }

#shopping_cart .cart-widget-remove,
#shopping_cart #remove,
#single_product_page_container .custom_meta {
  display: none; }

#shopping_cart #cart-widget-links a {
  float: left;
  width: 50% !important; }

.shopping-cart-wrapper .shoppingcart table th {
  width: 33%; }

.single-wpsc-product .entry-content fieldset legend {
  background: none; }

#content .wpsc-transaction-results-wrap a {
  padding: 10px 24px !important;
  margin: 10px 0; }

#content .productcol h3 {
  clear: none;
  margin-bottom: 10px; }

#content .imagecol {
  margin: 0 15px 15px 0 !important; }

.wpsc_checkout_forms div.wpsc_email_address,
#content .wpsc_checkout_forms .wpsc_checkout_table {
  float: left;
  width: 45%;
  margin-right: 5%; }

#content #checkout_page_container input.wpsc-visitor-meta {
  width: 230px; }

#content label.wpsc_email_address {
  color: #274c7a;
  font-weight: bold;
  font-size: 18px;
  line-height: 1.5em;
  margin: 0 0 20px 0;
  font-family: 'Oxygen', sans-serif !important; }

.wpsc_also_bought_item {
  float: left;
  margin: 0 10px 10px 0;
  text-align: center;
  font-size: .8em;
  line-height: 1.1em; }

.wpsc_also_bought_item a {
  width: 100%;
  float: left; }

.highlight {
  background-color: #ffea00; }

.clear {
  clear: both; }

hr.dashed {
  margin: 5px 0;
  border: 1px dashed #555555; }

.blueBtn:active,
.vfb-submit:active {
  position: relative;
  top: 1px; }

.blueBtn:focus,
.vfb-submit:focus {
  outline: none; }

#content table {
  border: 1px solid #e7e7e7;
  margin: 0 -1px 24px 0;
  text-align: left;
  width: 100%; }

#content tr th,
#content thead th {
  color: #777;
  font-size: 12px;
  font-weight: bold;
  line-height: 18px;
  padding: 9px 24px; }

#content tr td {
  border-top: 1px solid #e7e7e7;
  padding: 6px 24px; }

#content tr.odd td {
  background: #f2f7fc; }

.testimonial-area {
  padding: 0 0 0 0;
  margin: 0;
  background: url(../img/low-poly01.jpg) fixed center center;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -ms-background-size: cover; }

.testimonial-solid p {
  color: #000000;
  font-size: 16px;
  line-height: 30px;
  font-style: italic; }

section.hero-text {
  background: #F4783B;
  padding: 50px 0 50px 0;
  color: #fff; }

section.hero-text h1 {
  color: #fff; }

.testimonials blockquote {
  background: #efefef none repeat scroll 0 0;
  border: medium none;
  color: #666;
  display: block;
  font-size: 14px;
  line-height: 20px;
  padding: 15px;
  position: relative; }

.testimonials blockquote::before {
  width: 0;
  height: 0;
  right: 0;
  bottom: 0;
  content: " ";
  display: block;
  position: absolute;
  border-bottom: 20px solid #fff;
  border-right: 0 solid transparent;
  border-left: 15px solid transparent;
  border-left-style: inset;
  /*FF fixes*/
  border-bottom-style: inset;
  /*FF fixes*/ }

.testimonials blockquote::after {
  width: 0;
  height: 0;
  right: 0;
  bottom: 0;
  content: " ";
  display: block;
  position: absolute;
  border-style: solid;
  border-width: 20px 20px 0 0;
  border-color: #c5c5c5 transparent transparent transparent; }

.testimonials .carousel-info img {
  border: 1px solid #cccccc;
  border-radius: 150px !important;
  height: 75px;
  padding: 3px;
  width: 75px; }

.testimonials .carousel-info {
  overflow: hidden; }

.testimonials .carousel-info img {
  margin-right: 15px; }

.testimonials .carousel-info span {
  display: block; }

.testimonials span.testimonials-name {
  color: #2e8eda;
  font-size: 16px;
  font-weight: 300;
  margin: 23px 0 7px; }

.testimonials span.testimonials-post {
  color: #656565;
  font-size: 12px; }

img.size-auto,
img.size-full,
img.size-large,
img.size-medium,
.attachment img {
  max-width: 100%;
  /* When images are too wide for containing element, force them to fit. */
  height: auto;
  /* Override height to match resized width for correct aspect ratio. */ }

.alignleft,
img.alignleft,
.alignright,
img.alignright,
.aligncenter,
img.aligncenter {
  display: block;
  float: none;
  margin: 0 auto 12px; }

img.alignleft,
img.alignright,
img.aligncenter {
  margin-bottom: 12px; }

.wp-caption {
  background: #f1f1f1;
  line-height: 18px;
  margin-bottom: 20px;
  max-width: 632px !important;
  /* prevent too-wide images from breaking layout */
  padding: 4px;
  text-align: center; }

.wp-caption img {
  margin: 5px 5px 0; }

.wp-caption p.wp-caption-text {
  color: #888;
  font-size: 12px;
  margin: 5px; }

.wp-smiley {
  margin: 0; }

.gallery {
  margin: 0 auto 18px; }

.gallery .gallery-item {
  float: left;
  margin-top: 0;
  text-align: center;
  width: 33%; }

.gallery-columns-2 .gallery-item {
  width: 50%; }

.gallery-columns-4 .gallery-item {
  width: 25%; }

.gallery img {
  border: 2px solid #cfcfcf; }

.gallery-columns-2 .attachment-medium {
  max-width: 92%;
  height: auto; }

.gallery-columns-4 .attachment-thumbnail {
  max-width: 84%;
  height: auto; }

.gallery .gallery-caption {
  color: #888;
  font-size: 12px;
  margin: 0 0 12px; }

.gallery dl {
  margin: 0; }

.gallery img {
  border: 10px solid #f1f1f1; }

.gallery br + br {
  display: none; }

#content .attachment img {
  /* single attachment images should be centered */
  display: block;
  margin: 0 auto; }

@media (min-width: 480px) {
  .alignleft,
  img.alignleft {
    display: inline;
    float: left;
    margin-right: 24px;
    margin-top: 4px; }
  .alignright,
  img.alignright {
    display: inline;
    float: right;
    margin-left: 24px;
    margin-top: 4px; }
  .aligncenter,
  img.aligncenter {
    clear: both;
    display: block;
    margin-left: auto;
    margin-right: auto; } }
