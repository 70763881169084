

@media (min-width: 1400px) { 
	.container.lgContainer {width:1270px;}
}
@media (min-width: 1600px) {
	.container.lgContainer {width:1470px;}
}
@media (min-width: 1800px) { 
	.container.lgContainer {width:1700px;}
}
@media (min-width: 2000px) {
	.container.lgContainer {width:1900px;}
}
