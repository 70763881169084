img.size-auto,
img.size-full,
img.size-large,
img.size-medium,
.attachment img {
	max-width: 100%; /* When images are too wide for containing element, force them to fit. */
	height: auto; /* Override height to match resized width for correct aspect ratio. */
}
.alignleft,
img.alignleft,
.alignright,
img.alignright,
.aligncenter,
img.aligncenter {
	display: block;
	float: none;
	margin:0 auto 12px;
}
img.alignleft,
img.alignright,
img.aligncenter {
	margin-bottom: 12px;
}
.wp-caption {
	background: #f1f1f1;
	line-height: 18px;
	margin-bottom: 20px;
	max-width: 632px !important; /* prevent too-wide images from breaking layout */
	padding: 4px;
	text-align: center;
}
.wp-caption img {margin: 5px 5px 0;}
.wp-caption p.wp-caption-text {
	color: #888;
	font-size: 12px;
	margin: 5px;
}
.wp-smiley {margin: 0;}
.gallery {margin: 0 auto 18px;}
.gallery .gallery-item {
	float: left;
	margin-top: 0;
	text-align: center;
	width: 33%;
}
.gallery-columns-2 .gallery-item {width: 50%;}
.gallery-columns-4 .gallery-item {width: 25%;}
.gallery img {border: 2px solid #cfcfcf;}
.gallery-columns-2 .attachment-medium {max-width: 92%;height: auto;}
.gallery-columns-4 .attachment-thumbnail {
	max-width: 84%;
	height: auto;
}
.gallery .gallery-caption {
	color: #888;
	font-size: 12px;
	margin: 0 0 12px;
}
.gallery dl {margin: 0;}
.gallery img {border: 10px solid #f1f1f1;}
.gallery br+br {display: none;}
#content .attachment img {/* single attachment images should be centered */
	display: block;
	margin: 0 auto;
}
@media (min-width: 480px) {	 
	.alignleft,
	img.alignleft {
		display: inline;
		float: left;
		margin-right: 24px;
		margin-top: 4px;
	}
	.alignright,
	img.alignright {
		display: inline;
		float: right;
		margin-left: 24px;
		margin-top: 4px;
	}
	.aligncenter,
	img.aligncenter {
		clear: both;
		display: block;
		margin-left: auto;
		margin-right: auto;
	}
}