.gridrow {
	.gridboxes {
		text-align: center;
		height: 50vw;
		margin-bottom: 15px;
		@include ssm {height: 45vw;}
		@include sm {height: 20vw;max-height: 390px;}
		@include lg {height: 18vw;}
		@include xxl {height: 15vw;}
		@include yl {height: 12vw;}
		* {@include transition(0.4s);}
		a {
			text-decoration: none;
			display: block;
			width: 100%;
			height: 100%;
			padding: 0 10px;
    		margin: 0 auto;
			position: relative;
			background-color: $black;
			overflow: hidden;
			max-width: 520px;
			@include transition(0.4s);
		}
		.gridtext {
			color: $white;
			line-height: 1.1;
			position: relative;
			top: 50%;
			transform: translateY(-50%);
			z-index: 5;
			@include transition(0.4s);
			.grid-btn-text {
				font-size: 22px;
				padding-top:20px;
				margin-bottom:5px;
				@include md {font-size: 24px;}
				@include lg {font-size: 28px;}
			}
			.grid-sub-text {
				font-size: 18px;
				font-weight: 300;
				opacity: 0;
				@include md {font-size: 20px;}
				/*@include lg {font-size: 24px;}*/
			}
		}
		.grid-img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-repeat: none;
			background-size: cover;
			background-position: center;
			z-index: 1;
			opacity: 1;
			@include transition(0.4s);
			&:before {
			    content: '';
			    position: absolute;
			    top: 0;
			    left: 0;
			    width: 100%;
			    height: 100%;
			    background-color: rgba($black,.6);
			    @include transition(0.4s);
			    z-index: 2;
			}
		}
		&:hover {
			.gridtext {
				.grid-btn-text {
					padding-top:0;
				}
				.grid-sub-text {
					padding-top:0;
					opacity: 1;
				}
			}
			.grid-img {
				transform: scale(1.15);
				&:before {
					background-color: rgba($black,.8);
				}
			}

		}
	}
}