form#contactform input[type="text"] {
  width: 100%;
  border: 1px solid #f5f5f5;
  min-height: 40px;
  padding-left:20px;
  font-size:13px;
  padding-right:20px;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;

}

form#contactform textarea {
border: 1px solid #f5f5f5;
  width: 100%;
  padding-left:20px;
  padding-top:10px;
  font-size:13px;
  padding-right:20px;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;

}

form#contactform .validation {
	font-size:11px;
}

#sendmessage {
	border:1px solid #e6e6e6;
	background:#f6f6f6;
	display:none;
	text-align:center;
	padding:15px 12px 15px 65px;
	margin:10px 0;
	font-weight:600;
	margin-bottom:30px;

}

#sendmessage.show,.show  {
	display:block;
}
 
form#commentform input[type="text"] {
  width: 100%;
  min-height: 40px;
  padding-left:20px;
  font-size:13px;
  padding-right:20px;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
	-webkit-border-radius: 2px 2px 2px 2px;
		-moz-border-radius: 2px 2px 2px 2px;
			border-radius: 2px 2px 2px 2px;

}

form#commentform textarea {
  width: 100%;
  padding-left:20px;
  padding-top:10px;
  font-size:13px;
  padding-right:20px;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
	-webkit-border-radius: 2px 2px 2px 2px;
		-moz-border-radius: 2px 2px 2px 2px;
			border-radius: 2px 2px 2px 2px;
}