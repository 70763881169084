

.icons {
	padding: 0 15px;
	margin-top:20px;
	>.pad {
		padding:20px 0 0;
		display: table;
		width: 100%;
		background-color: $lgray;
	}
	.innerbox {
		max-width: 400px;
    	margin: 0 auto;
    	@include sm {max-width: 700px;}
    	@include md {max-width: 850px;}
	}
	.nextbox {
		text-align: center;
		float: left;
		vertical-align: top;
		width: 25%;
		&.fbox {
			font-size: 30px;
			font-weight: bold;
			width:100%;
			padding:0;
			>div {
				display: inline-block;
				vertical-align: middle;
			}
			.next-icon {
				height: auto;
				width: auto;
				background-color: transparent;
				display: inline-block;
			}
		}
		&.cross {
			margin-left: 0;
		}
		a {
			color: $dgray;
			display: block;
			text-decoration: none;
			padding: 20px 10px;
    		line-height: 1;
    		text-transform: uppercase;
    		&:hover {
    			color:$blue !important;
    			background-color: $white;
    		}
		}
		.next-icon {
			font-size: 30px;
			height: 60px;
			width: 60px;
			margin:0 auto 15px;
			background-color: $white;
			border-radius: 60%;
			display: table;
			.fa {
				display: table-cell;
    			vertical-align: middle;
			}
		}
	}
	&.cross-current {
		.cross a {color: $green;background-color: $white;}
	}
	&.church-new-current {
		.church-new a {color: $green;background-color: $white;}
	}
	&.hand-current {
		.hand a {color: $green;background-color: $white;}
	}
	&.bible-current {
		.bible a {color: $green;background-color: $white;}
	}
}