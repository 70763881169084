#content .bs_title {margin-bottom:10px;}
.btn_group {width:100%;float:left;clear:both;margin-bottom: 15px;}
.filter-title {margin:0 5px 5px 0;}
.business ul {
    margin:0;
    padding:0;
    list-style:none;
}
.filter-title, #filterOptions > li {float:left;line-height:28px;}
#filterOptions {
    > li {
        > a {
           display:block;
           margin-bottom:3px;
           padding:0 8px;
           border: 1px solid #ddd;
           background-color:rgba(255,255,255,0.8);
           -webkit-transition: .25s ease;
           -moz-transition: .25s ease;
           -o-transition: .25s ease;
           -ms-transition: .25s ease;
           transition: .25s ease;
           text-decoration:none !important;
       }
       &.active > a,
       &:hover > a {color:#fff;background-color: #125da8;}
   }
}

.business ul.bsgrid {
    display:table;
    margin-right: -15px;
    margin-left: -15px;
    .item {text-align:center;position: relative;margin-bottom: 15px;}
}

.inner {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    z-index: 100;
    .item-hover {
        height: 100%;
        position: relative;
        text-align: center;
        display: block;
        width: 100%;
        z-index: 100;
        border: 1px solid #555;
        .mask {
            background-color: #000;
            -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
            filter: alpha(opacity=50);
            height: 100%;
            opacity: 0;
            width: 100%;
            z-index: 99;
            top: 0;
            left: 0;
            position: absolute;
            -webkit-transition: all 300ms ease-out;
            -moz-transition: all 300ms ease-out;
            -o-transition: all 300ms ease-out;
            transition: all 300ms ease-out;
        }
    }
    .item-title,
    .item-info {position: absolute;}
    .item-title {
        color:#555;
        width:100%;
        bottom:0;
        z-index: 98;
        padding:10px 0;
        background-color:rgba(255,255,255,0.7);
        -webkit-transition: all 300ms ease-out;
        -moz-transition: all 300ms ease-out;
        -o-transition: all 300ms ease-out;
        transition: all 300ms ease-out;
    }
    .item-info {
        display: table;
        z-index: 1000;
        height: 100%;
        width: 100%;
        top: 50%;
        transform: translateY(-50%);
    }
}

.headline {
    display: table-cell;
    vertical-align: middle;
}
.item-img {
    overflow: hidden;
    width: 100%;
    height: 100%;
    max-width: 100%;
    vertical-align: middle;
    position: relative;
    z-index: 9;
    transition: all 0.3s ease-in-out 0s;
}
.item-info,
.item-hover .mask,
.item-hover:hover .item-title {opacity:0;}
.item-hover:hover .item-info, .item-hover:hover .mask {opacity: 0.7;}
.item-hover:hover .item-img {transform: scale(1.1, 1.1)!important;}
.single-business {
    >.btn {
        margin-bottom:20px;
    }
    .business-info {
        .business-row {
            margin-bottom:10px;
            padding-bottom: 10px;
            position: relative;
            display: flex;
            &:before,&:after {
                content:'';
                position: absolute;
                left:15px;
                width:92%;
                height:1px;
            }
            &:after {
                content:'';
                position: absolute;
                bottom:0;
                background-color: rgba($mgray,0.9);
            }
            &:first-child, &.business-owner {
                padding-top: 10px;
                &:before {
                    top:0;
                    background-color: rgba($mgray,0.9);
                }
            }
            &:last-child {
                &:after {
                    display: none;
                }
            }
            &.business-map {
                .b-map {
                    margin-top:15px;
                }
                .btn {
                    margin-top:15px;
                }
            }
        }
    }
}

/* Small devices (large mobile, 500px and up) */
@media (min-width: 500px) {
    .bsgrid .item {width:50%;}
}
/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .bsgrid .item {width: 33.33333333%;}
}
/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .bsgrid .item {width: 25%;}
}
.acf-map {
    width: 100%;
    height: 0;
    padding-bottom: 50%;
    img {
       max-width: inherit !important;
    }
}
