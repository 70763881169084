
.sermon {
    text-align: center;
    margin-bottom:15px;
    > .pad {
        padding: 20px 10px;
        border: 1px dashed $gray;
    }
    .sermon-title {
        font-size: 1.3em;
        font-weight: 600;
        margin-bottom: 10px;
    }
    .sermon-date {
        font-size: 1.1em;
        margin-bottom: 8px;
    }
    a.sermon-link {
        text-decoration: none;
        display: inline-block;
        padding:0 5px;
        &:hover {
            color: $red;
        }
        .pdf-icon {
            color: $red;
        }
        .ppt-icon {
            color: $green;
        }
    }
}