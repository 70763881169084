#footer_container {
    color: $white;
    background-color: $dgray;
}
#footer {
    text-align: center;
    padding: 20px 0;
    @include sm {padding: 30px 0;}
    @include md {text-align: left;}
    @include xl {padding: 40px 0;}
    .container {
        @include xl {
            display: table;
            width: 100%;
            margin: 0 auto;
            max-width: 1350px;
        }
        @include xxl {max-width: 1500px}
    }
    ul {@include list(0);}
    h3 {
        color: $lblue;
        font-size:18px;
        font-weight: 400;
        text-transform: uppercase;
        margin-bottom: 10px;
    }
    a {
        color: $white;
        display: block;
        text-decoration: none;
    }
    a:hover {color:$lblue;}
    .ftr-box, .logobox {
        margin-bottom:20px;
    }
    .ftr-info {
        font-size: 16px;
        font-weight: 300;
        max-width: 220px;
        display: table;
        margin:0 auto 10px;
        width: 100%;
        .ftr-phone, .ftr-address {
            text-align: left;
            display: table;
            margin:0 auto 10px;
            width: 100%;
        }
        .icon, .text, .mobile-only {
            display: inline-block;
            vertical-align: top;
        }
        .icon {
            width: 15%;
            margin-right: 5%;
            font-size: 20px;
            text-align: right;
        }
        .text {width: 80%;}
        .desktop-only {
            display: none;
            @include md {display: inline-block;}
        }
        .mobile-only {
            @include md {display: none;}
        }
    }
    .ftr-social {
        .social-title {
            color: $lblue;
            font-size: 24px;
            margin-bottom:10px;
        }
        .smedia {
            display: table;
            margin:0 auto;
            li {
                display: inline-block;
                width: 34px;
                height: 34px;
                line-height: 34px;
                margin:0 3px;
            }
            a {
                color: $dgray;
                font-size: 20px;
                display: block;
                background-color: $lgray;
                &:hover {
                    color:$lgray;
                    background-color:$gray;
                }
            }
        }
    }
    .menu-box {
        display: none;
        @include md {
            float:left;
            width:75%;
            display: block;
        }
        @include xl {width: 82%;}
    }
    .ftr-box {
        &#box-1 { 
            @include md {
                float:left;
                width:25%; 
                text-align: center;
            }
            @include xl {width: 18%;}
        }
        &#box-2, &#box-3,
        &#box-4, &#box-5, 
        &#box-6, &#box-7 {
            display: inline-block;
            margin-left:-5px;
            width:33.333%;
            vertical-align: top;
            display: inline-block;
            @include xl {width: 16%;}
        }
        >.pad {
            @include md {
                padding:0 10px;
                display: table;
                width: 100%;
            }
        }
    }
    .menu>li {
        float:left;
        text-align: left;
        line-height: 1;
        width:100%;
        border-bottom:1px solid rgba($lgray, 0.3);
        position: relative;
    }
    .menu>li>a {
        font-size: 16px;
        font-weight: 300;
        padding:12px 5px;
        display: block;
    }
    .menu>li>a:before {
        content: '\f105';
        font-family: FontAwesome;
        margin-right:10px;
    }
    .textwidget {
        font-size: .9em;
        line-height: 1em;
    }
    .widget_sp_image h3 {display: none;}
    .widget_sp_image img {
        max-height: 70px;
        width: auto;
    }
}
#ftr_bottom {
    color:$white;
    font-weight: 300;
    background-color: $black;
    padding:10px 0;
    @include sm {text-align: center;}
    #site-info, #site-generator {
        text-align: center;
        font-size: .8em;
        @include sm {
            display: inline-block;
            margin: 0 5px;
        }
    }
    a {
        color: $white;
        text-decoration: none;
    }
}