.home-message {
	padding:20px 0;
	@include sm {padding:30px 0;}
	@include xl {padding:40px 0;}
	&.bible-study {
		.image-block {
			padding-bottom: 20px;
			@include sm {padding-bottom: 0;}
		}
	}
	.radiospot {
		audio {width: 100%;}
	}
}
.home-btnbox {
	text-align: center;
	display: table;
    max-width: 350px;
    margin: 0 auto 15px;
    @include sm {max-width: 100%;}
	.home-btn {
		display: inline-block;
		margin:0 5px 10px;
		a {color: $white !important;}
	}
}
.home-services {
	>.pad {
		max-width: 1000px;
    	margin: 0 auto;
	}
	.service {
		margin-bottom: 15px;
	}
	.service_link {
		color: $white;
		text-align: center;
		display: block;
		height: 170px;
		text-decoration: none;
		overflow: hidden;
		@include transition(0.3s);
		position: relative;
		max-width: 370px;
		margin:0 auto;
		@include md {
			height: 250px;
			max-width: 460px;
		}
		@include lg {
			height: 280px;
			max-width: 470px;
		}
		&:hover {
			.service-img {@include transform (1.15);}
			.servicebox {
				padding:50px 20px 0;
				@include md {padding-top:95px;}
			}
			.service-text {opacity: 1;margin-top:10px;}
			.service-img:before {background-color: rgba($black,0.8);}
		}
		.service-img {
			height: 100%;
			width: 100%;
			background-size: cover;
			background-position: center center;
			background-repeat: no-repeat;
			@include transition(0.4s);
			z-index: 1;
			position: relative;
			&:before {
				content:'';
				position: absolute;
				top:0;
				left:0;
				width: 100%;
				height: 100%;
				background-color: rgba($black,0.6);
				@include transition(0.4s);
				z-index: 2;
			}
		}
		.servicebox {
			padding:75px 20px 0;
			position: absolute;
			z-index: 5;
			width: 100%;
			@include transition(0.4s);
			@include md {padding-top:115px;}
			.valign {
				display: table;
				width:100%;
				max-width: 320px;
				margin:0 auto;
			}
		}
		.service-title {
			font-size: 28px;
			@include md {font-size: 32px;}
		}
		.service-text {
			color: $lgray;
			font-size: 14px;
			font-weight: 300;
			margin-top:10px;
			opacity: 0;
			@include transition(0.4s);
			@include md {font-size: 16px;}
		}
	}
}
