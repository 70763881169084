.cta_block {
	>.pad {
		padding:20px 0;
		@include md {
			padding:40px 30px;
			display: table;
    		width: 100%;
    		max-width: 1600px;
    		margin: 0 auto;
		}
	}
	.cta {
		@include md {padding:0 10px;}
	}
	.cta_link, .cta-text,
	.overlay {
		@include transition(0.4s);
	}
	.cta_link {
		color: $white;
		text-align: center;
		text-decoration: none;
		display: block;
		height: 270px;
		position: relative;
		max-width: 470px; 
		margin: 0 auto 15px;
		@include sm {margin-bottom: 20px;}
		@include lg {height: 310px;}
		@include xl {height: 380px;}
		@include xxl {height: 400px;}
		&:hover {
			.overlay {
				opacity: 0.7;
			}
			.cta-text { 
				padding-top:70px;
				@include md {padding-top:50px;}
				@include xl {padding-top:100px;}
			}
		}
		.overlay,
		.cta-img:before {
			position: absolute;
			top:0;
			left: 0;
			width: 100%;
			height:100%;
			z-index: 2;
			opacity: 0;
		}
		.cta-img {
			width: 100%;
			height: 100%; 
			background-size: cover;
			background-position: center center;
			background-repeat: no-repeat;
			&:before {
				content:'';
				background-color: rgba($black, 0.3);
				opacity: 1;
			}
		}
		.cta-text {
			position: relative;
			z-index: 5;
			padding-top:100px;
			@include md {padding-top:70px;}
			@include xl {padding-top:150px;}
		}
		.cta-big-title {
			font-size: 28px;
			font-weight: bold;
			margin:15px auto;
		}
		.cta-small-title {
			font-style: italic;
		}
	}
}
.second_cta_block {
	>.pad {
		padding:20px;
		display: table;
		width: 100%;
		margin: 0 auto;
		max-width: 1200px;
		@include md {padding: 40px 20px;}
	}
	.second-cta {
		text-align: center;
		@include md {padding: 0 5px;}
		.border {
			max-width: 350px;
			padding-bottom: 15px;
			margin: 0 auto 15px;
			border-bottom: 1px dashed $mgray;
			@include sm {
				border:none;
				margin-bottom: 0;
				padding-bottom: 0;
			}
			@include xl {max-width: 355px;}
		}
		&:last-child .border  {
			padding-bottom: 0;
			margin-bottom: 0;
			border-bottom: none;
		}
		img {
			display: none;
			@include sm {display: block;}
		}
	}
	.cta-img {
		margin-bottom: 15px;
		position: relative;
		&:after {
			content:'';
			position: absolute;
			top: 0;
			left:0;
			width: 100%;
			height:100%;
			z-index: 1;
			background-color: rgba($black,0.4);
			display: none;
			@include sm {display: block;}
		}
		.cta-icon {
			color: $black;
			font-size: 48px;
			@include sm {
				color: $white;
				text-align: center;
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				width: 100%;
				z-index: 5;
			}
		}
	}
	.cta-bottom {
		.cta-big-title {
			color: $blue;
			font-size: 28px;
			font-weight: bold;
			margin-bottom: 10px;
		}
		.cta-small-title {

		}
		.btnbox {
			margin:20px auto;
		}
	}
}