#hdr {
	position: relative;
	z-index: 99;
}
#hdr_top {
	font-size: 16px;
	height: 0;
	background-color: $lgray;
	@include sm {height: auto;}
} 
#hdr_nav {
	background-color: $black;
	@include sm {
		padding:8px 0;
	}
	@include md {
		padding:0;
		background: none;
		display: table;
    	margin: 0 0 0 auto;
	}
	ul {
		@include list(0);
	}
	a {display: block;}
	.social {
		float:left;
		text-align: center;
		height:30px;
		width:30px;
		line-height: 30px;
		margin:0 4px;
	}
	.social a {
		color:$white;
		font-size: 1.3em;
		background-color: $blue;
		width:100%;
		border-radius: 30px;
	}
	.social a:hover {
		background-color: $red;
	}
}

#hdr_bottom {
	padding:8px 0;
	background-color: rgba($white, 0.9);
	position: relative;
	z-index: 99;
	@include sm {
		padding:0;
	}
	.logo {
		float: left;
    	margin: 0 auto;
    	@include sm {padding:10px 0;}
	}
	.logo img { 
		max-height: 70px;
		@include transition(0.3s);
    	@include sm {max-height: 65px;}
	}
	#trigger {
		color:$blue;
		float: right;  
		font-size:30px;
		line-height:70px;
		@include sm {line-height:85px;}
	}
	#trigger span {font-size:18px;text-transform:uppercase;}
	&.sticky {
		position: fixed;
		top:0;
		border-bottom: 1px solid $lgray;
		.logo img {
			max-height: 50px;
		}
		#trigger {
			line-height:50px;
		}
	}
	.top_phone {
		float:left;
		width:80%;
		@include sm {
			float:right;
			width:auto;
			margin-top:20px;
		}
	}
	.phone {
		color: $blue;
		font-size: 1.6em;
		font-weight: 600;
		line-height:30px;
		text-align: left;
		a {color: $gray;text-decoration: none;}
	}
	.slogan {
		color: $gray;
		float:left;
		text-transform: uppercase;
	    font-size: .9em;
	    font-weight: bold;
	    margin-top: 30px;
	    @include sm {
	    	margin-left:30px;
	    }
	}
}