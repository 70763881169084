#main {
	padding:20px 15px;
	@include lg {padding:40px 15px;}
}
.has-sidebar {
	#main {
		padding:20px 15px;
		@include md {padding:0 15px;}
		@include lg {padding:0 15px;}
	}
}
.main-content {
	&.with-sidebar {
		@include md {
			display: table-cell;
			vertical-align: top;
			width: 75%;
			padding:20px 0 20px 30px;
		}
	}
}
#content {
	position:relative;
	padding:20px 0 10px;
	display: table;
    width: 100%;
	@clude sm {padding:30px 0 20px;}
	@clude md {padding:40px 0 30px;}
	h1, h2, h3, h4, h5, h6 {
		font-weight: bold;
	}
	h3 {}
	p, ul {
			a {
			color: $lblue;
			&.btn {color:$white;}
		}
	}
	.visual-form-builder {
		fieldset {
			border:none;
			border-radius: 0;
			background:none;
		}
		.vfb-legend {
			display:none;
		}
		ul.vfb-section {
			margin:0 !important;
		}
		ul.vfb-section > li {
			padding:0 !important;
			margin-top:10px !important;
			@include sm {margin-top:20px !important;}
		}
		ul.vfb-section label {
			color: $gray; 
			font-size: 1.1em;
		}
		ul.vfb-section > li > input,
		ul.vfb-section > li textarea,
		ul.vfb-section > li select,
		ul.vfb-section > li input  {
			padding:0 5px;
			line-height: 30px;
			height: 30px;
			background: none repeat scroll 0 0 #fefefe;
			border-radius: 0;
		}
		ul.vfb-section > li textarea  {
			&.vfb-textarea.vfb-medium {
				padding:5px;
				line-height: 1.1;
				height: 60px;
			}
			&.vfb-textarea.vfb-large {
				padding:5px;
				line-height: 1.1;
				height: 90px;
			}
		}
		.vfb-item-submit {
			text-align: center;
		}
		ul.vfb-section > li.vfb-item-submit > input {
			color: $white;
			font-size: 1.3em;
			width:60%;
			max-width: 300px;
			border:none;
			background: $red;
			@include transition(0.2s);
			@include sm {
				line-height: 40px;
				height: 40px;
			}
		}
		ul.vfb-section > li.vfb-item-submit > input:hover {
			background:$blue;
		}
	}
	.faqs-list {
		.faq {
			display: table;
			width: 100%;
			margin-bottom:10px;
			.faq-q {
				font-size: 1.1em;
				font-weight: 600;
				margin-bottom:10px;
			}
			.faq-a {

			}
		}
	}
	.contact-info {
		margin-bottom:20px;
		.info-box {
			margin-bottom:15px;
			>* {
				display: table;
				width:100%;
				padding-bottom:10px;
				max-width: 350px;
    			margin: 0 auto;
			}
		}
		.info-label {
			font-weight: 600;
			line-height: 1;
			float:left;
			width: 38%;
			@include sm {width: 32%;}
			@include lg {width: 40%;}
		}
		.info-text {
			float:left;
		}
	}
	.navigation {
		.nav-previous {
			float:left;
		}
		.nav-next {
			float:right;
		}
	}
	.radiospot {
		margin-bottom: 10px;
		@include sm {margin-bottom: 20px;}
		&:nth-child(2n) {
		    @include sm {clear: left;}
		}
	}
	.sofia {
		.tg-item-title,
		.tg-item-title a {
			@include RobotoC();
			font-weight: normal;
			font-size: 1em;
    		line-height: 1.1;
			padding: 0 !important;
			text-transform: none;
		}
	}
}
.modular {
	.modpad {
		padding:20px 0;
		overflow-y: scroll;
		@include sm {padding:30px 0;}
		@include md {padding:40px 0;}
		@include lg {padding:50px 0;}
	}
	.icons, #content {
		padding:0;
	}
	.contentbox {
		.menu-page-menu-container {
			ul {
				@include list(0);
				display: table;
				li {
					border-bottom: 1px solid $mgray;
					&:first-child {border-top: 1px solid $mgray;}
					a {
						color: $bgray !important;
						text-decoration: none;
						line-height: 1;
						display: block;
						padding:15px 25px 15px 15px;
						position: relative;
						@include sm {padding:20px 30px 20px 20px;}
						&:after {
							content:'\f054';
							font-family: FontAwesome;
							position: absolute;
    						right: 7px;
    						@include transition(0.25s);
						}
						&:hover {
							color:$black !important;
							/*padding:15px 20px 15px 20px;*/
							background-color: $lgray;
							/*@include sm {padding:20px 25px 20px 25px;}*/
							&:after {right: 2px;}
						}
					}
				}
			}
		}
	}
}
.tablebox {
	display: table;
	width: 100%;
}
