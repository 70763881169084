@mixin font-size($pixelsize: 16, $line: 1.428) {
  font-size: $pixelsize + px;
  font-size: ($pixelsize / 10) + rem;
  line-height: $line; 
}

@mixin RobotoC() {font-family: 'Roboto Condensed', sans-serif;}
@mixin Alice() {font-family: 'Alice', serif;}
@mixin Noto() {font-family: 'Noto Serif', serif;}

@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}
@mixin transition ($speed) {
    -webkit-transition: all $speed ease;
    -o-transition: all $speed ease;
    -ms-transition: all $speed ease;
    -moz-transition: all $speed ease;
    transition: all $speed ease;
}
@mixin transform ($size) {
    -webkit-transform: scale($size);
    -o-transform: scale($size);
    -ms-transform: scale($size);
    -moz-transform: scale($size);
    transform: scale($size);
}
@mixin list($margin) {
  margin:$margin;
  padding:0;
  list-style: none;
}
@mixin xsm {
  @media (min-width: 480px) {
    @content;
  }
}
@mixin ssm {
  @media (min-width: 600px) {
    @content;
  }
}
@mixin sm {
  @media (min-width: 768px) {
    @content;
  }
}
@mixin md {
  @media (min-width: 992px) {
    @content;
  }
}
@mixin lg {
  @media (min-width: 1200px) {
    @content; 
  }
}
@mixin xl {
  @media (min-width: 1400px) {
    @content;
  }
}
@mixin xxl {
  @media (min-width: 1600px) {
    @content;
  }
}
@mixin yl {
  @media (min-width: 1800px) {
    @content;
  }
}
@mixin zl {
  @media (min-width: 2000px) {
    @content;
  }
}