
#mmenu.mm-menu {
	z-index: 999;
	background: $blue;
	.mm-panel {
		a {color: $lgray;}
		&#mm-1 {
			padding-top:0;
		}
	}
	.mm-panel#mm-1 > .mm-navbar {display:none;}
	em.mm-counter {color: inherit;}
	.mm-listview>li:not(.mm-divider):after {
		left:0;		
	}
	.mm-listview>li, .mm-listview>li .mm-next, 
	.mm-listview>li .mm-next:before, 
	.mm-listview>li:after,
	.mm-listview>li .mm-next:after,
	.mm-navbar, .mm-btn:after, 
	.mm-btn:before {border-color: rgba($lgray, 0.5);}
	.menu a, .mm-navbar>* {
		color: $lgray;
		font-size: 18px;
		font-weight: 300;
	}
}
