#searchToggle {
    color: $gray;
    float:right;
    display: block;
    padding:0 15px;
    line-height: 40px;
    &:hover {
        color:$black;
    }
}
.site_search_wrap {
    display: none;
    width: 100%;
    background-color: rgba($blue, 0.9);
    position: absolute;
    top: 125px;
    z-index: 99;
    .pad {
        padding: 60px 40px 30px;
        @include md {padding: 60px 40px 40px;}
    }
    .close_div {
        color:#fff;
        text-align: center;
        position: absolute;
        right: 20px;
        top: 10px;
        display: table;
        width: 30px;
        height:30px;
        line-height: 30px;
        -moz-border-radius: 50%;
        -webkit-border-radius: 50%;
        border-radius: 50%;
        border: 1px solid #fff;
        @include transition(0.2s);
    }
    .close_div:hover {opacity:0.7;}
    .site_search {
        width: 100%;
        margin: 0 auto;
        display: block;
        position: relative;
        max-width: 1000px;
    }
    .site_search label {
        color: $blue;
        font-weight: 300;
        position: absolute;
        top: 22px;
        left: 21px;
        @include transition(0.2s);
    }
    .site_search.active label {
        opacity: 0.5;
    }
    .site_search a.site_search_clear {
        display: block;
        position: absolute;
        top: 27px;
        right: 90px;
    }
    .site_search input[type="text"] {
        width: 100%;
        border: 0;
        height: 60px;
        padding: 20px;
        background-color: #fff;
        border: 1px solid #fff;
        @include sm {height: 70px;}
    }
    .site_search input[type="text"]:focus {
        outline: -webkit-focus-ring-color auto 1px;
    }
    .site_search .search_submit {
        color: #fff;
        background-color: $red;
        width: 60px;
        height: 60px;
        position: absolute;
        right: 0;
        top: 0;
        line-height: 60px;
        text-align: center;
        font-size: 30px;
        @include sm {
            width: 70px;
            height: 70px;
            line-height: 70px;
        }
    }
}
.sticky .site_search_wrap {
    top:106px;
}