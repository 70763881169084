.testimonial-area {
padding: 0 0 0 0;
margin:0;
background: url(../img/low-poly01.jpg) fixed center center;
background-size: cover;
-webkit-background-size: cover;
-moz-background-size: cover;
-ms-background-size: cover;
}
.testimonial-solid p {
color: #000000;
font-size: 16px;
line-height: 30px;
font-style: italic;
} 
section.hero-text {
	background: #F4783B;
	padding:50px 0 50px 0;
	color: #fff;
}
section.hero-text h1{ 
	color: #fff;
}
.testimonials blockquote {
    background: #efefef none repeat scroll 0 0;
    border: medium none;
    color: #666;
    display: block;
    font-size: 14px;
    line-height: 20px;
    padding: 15px;
    position: relative;
}
.testimonials blockquote::before {
    width: 0; 
    height: 0;
	right: 0;
	bottom: 0;
	content: " "; 
	display: block; 
	position: absolute;
    border-bottom: 20px solid #fff;    
	border-right: 0 solid transparent;
	border-left: 15px solid transparent;
	border-left-style: inset; /*FF fixes*/
	border-bottom-style: inset; /*FF fixes*/
}
.testimonials blockquote::after {
    width: 0;
    height: 0;
    right: 0;
    bottom: 0;
    content: " ";
    display: block;
    position: absolute;
    border-style: solid;
    border-width: 20px 20px 0 0;
    border-color: #c5c5c5 transparent transparent transparent;
}
.testimonials .carousel-info img {
    border: 1px solid #cccccc;
    border-radius: 150px !important;
    height: 75px;
    padding: 3px;
    width: 75px;
}
.testimonials .carousel-info {
    overflow: hidden;
}
.testimonials .carousel-info img {
    margin-right: 15px;
}
.testimonials .carousel-info span {
    display: block;
}
.testimonials span.testimonials-name {
    color: #2e8eda;
    font-size: 16px;
    font-weight: 300;
    margin: 23px 0 7px;
}
.testimonials span.testimonials-post {
    color: #656565;
    font-size: 12px;
}