.staff-row {
	text-align: center;
	.staff-box {
		width: 100%;
		display: inline-block;
		padding: 0 30px 30px;
	    background-color: $lgray;
	    border: 1px solid $mgray;
	    min-height: 250px;
	    max-width: 335px;
	    margin: 110px auto 25px;
	    @include sm {margin: 110px 10px 25px;}
	    .staff-img {
		    display: block;
		    margin: -100px auto 0;
		    img {
		    	height: 200px;
			    border: 1px solid $mgray;
			    max-width: 100%;
		    	border-radius: 50%;
		    }
	    }
	    .staff-name,.staff-title,
	    .staff-social {text-align: left;}
	    .staff-name {
	    	@include font-size(20,1);
	    	font-weight: 600;
	    	margin:20px 0 10px;
	    }
	    .staff-title {
	    	@include font-size(15,1);
	    	margin-bottom: 10px;
	    }
	    .staff-social {
	    	@include list(0);
	    	li {
	    		float:left;
	    		margin-right:5px;
	    		&.staff-facebook {background-color: #365698;}
	    		&.staff-twitter {background-color: #54cdf4;}
	    		&.staff-instagram {background-color: #396ea1;}
	    		&.staff-youtube {background-color: #d8322f;}
	    		a { 
	    			color: $white !important;
	    			text-align: center;
		    		font-size: 20px;
		    		line-height: 30px;
	    			display: block;
		    		height:30px;
		    		width:30px;
	    		}
	    	}
	    }
	}
}