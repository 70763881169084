/*@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?1q8zfw');
  src:  url('fonts/icomoon.eot?1q8zfw#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?1q8zfw') format('truetype'),
    url('fonts/icomoon.woff?1q8zfw') format('woff'),
    url('fonts/icomoon.svg?1q8zfw#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}*/

@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?cvu8hf');
  src:  url('fonts/icomoon.eot?cvu8hf#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?cvu8hf') format('truetype'),
    url('fonts/icomoon.woff?cvu8hf') format('woff'),
    url('fonts/icomoon.svg?cvu8hf#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {@include RobotoC;}
h1, h2, h3, .home .h2,
.ftr-social .social-title {@include Alice;}
.service-text, .cta-small-title, .banner {@include Noto;}

h1,h2,h3,h4,h5,h6,
.h1,.h2,.h3,.h4,.h5,.h6  {
    line-height:1.1em;
    margin: 0 0 20px;
}
p {
  margin:0 0 15px;
  @include lg {margin-bottom: 20px;}
}
.home {
  h1, .h1 {
    color: $blue;
    font-size: 32px;
    line-height: 36px;
    @include md {
      font-size: 36px;
      line-height: 40px;
    }
  }
  h2, .h2 {
    color: $dgray;
    font-size: 28px;
    line-height: 32px;
    @include md {
      font-size: 32px;
      line-height: 36px;
    }
  }
  h3, .h3 {
    color: $black;
    font-size: 24px;
    line-height: 28px;
    @include md {
      font-size: 28px;
      line-height: 32px;
    }
  }
  h4, .h4 {
    font-size: 20px;
    line-height: 24px;
    @include md {
      font-size: 24px;
      line-height: 28px;
    }
  }
}

.icon-hand:before {content: "\e901";}
.icon-bible:before {content: "\e902";}
.icon-cross:before {content: "\e903";}
.icon-church-new:before {content: "\e904";}
.icon-church:before {content: "\e900";}
.icon-headphones:before {content: "\e910";}
.icon-location:before {content: "\e948";}
.icon-youtube:before {content: "\ea9e";}