@import "mixins";
@import "colors";

.upper {text-transform: uppercase;}
.nopadding {padding:0;}

body {
  color: $gray;
  font-size: 16px;
  line-height:22px;
  @include md {
    font-size: 18px;
    line-height: 24px;
  }
}
a, .btn {@include transition(0.2s);}
a {color: $blue;}
a:active {outline:0;}
img {max-width: 100%;}
.clear {clear:both;}
.btn, .wpsc_buy_button,
.blueBtn,
.vfb-submit,
#content .wpsc-transaction-results-wrap a {
  color: $white;
  font-size: 1em;
  font-weight: 400;
  padding: 6px 25px;
  border-radius: 0;
  text-align: center;
  background: $blue;
  border-color: $blue;
  &:hover {
    color: $white;
    background-color: $dblue;
  }
  @include md {padding: 10px 30px;}
}
.btn.btn-red {
  color: $white !important;
  background: $red;
  border-color: $red;
  &:hover {
    color: $white;
    background-color: $blue;
    border-color: $blue;
  }
}

.full {
  float:left;
  width:100%;
}
.thin {font-weight: 300;}
.bold {font-weight: bold;}
.upper {text-transform: uppercase;}
.mobile-only {
  @include md {display: none;}
}
.desktop-only {
  display: none;
  @include md {display:block;}
}
.divider {
  text-align: center;
  float:left;
  width:100%;
  height:40px;
  margin: 15px 0;
  display: block;
  .pad {
    display: table;
    width: 100%;
    padding: 0 15px;
    position: relative;
    &:before, &:after {
      content:'';
      position: absolute;
      height: 1px;
      width: 32%;
      background: $mgray;
      margin: 20px 0;
      @include sm {width: 35%;}
      @include md {width: 38%;}
      @include lg {width: 40%;}
      @include xl {width: 42%;}
    }
    &:before {left:15px;}
    &:after {right:15px;}
  }
  span {display: inline-block;}
  img {max-height: 40px;}
}
.animateBlock {
  margin-top: 20px;
  display: inline-block;
  opacity: 0;
  filter: alpha(opacity=0);
  position: relative;
  -webkit-transition: all .4s ease-in;
  -moz-transition: all .4s ease-in;
  -ms-transition: all .4s ease-in;
  -o-transition: all .4s ease-in;
  transition: all .4s ease-in;
  &.left {left: -20%;}
  &.right {right: -20%;}
  &.up {bottom: -20%;}
}
.animated {
  opacity: 1;
  filter: alpha(opacity=100);
  &.left {left: 0%;}
  &.right {right: 0%;}
  &.up {bottom: 0%;}
}
